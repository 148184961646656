import { ProductDefinition } from 'graphql-types';
import useConfig from 'hooks/useConfig';
import { getProductConfig, useTemplatesQuery } from 'hooks/useGraphQlQuery';
import { useCallback } from 'react';

export const useProductInfo = () => {
  const { rootAssetConfig } = useConfig();
  const { data: config } = useTemplatesQuery<ProductDefinition[]>(
    { fn: getProductConfig, key: 'getProductConfig' },
    { templateInfo: rootAssetConfig?.templates },
    {
      enabled: !!rootAssetConfig?.templates,
      cacheTime: 60 * 60 * 1000,
    }
  );

  const info = useCallback(
    (type?: string) => {
      if (!config || !type) {
        return undefined;
      }
      return config.find((it) => it.type === type);
    },
    [config]
  );

  const getAggregates = useCallback(
    (aggregate: string) => {
      return (
        config
          ?.filter((it) => it.aggregatesTo.includes(aggregate))
          .map((it) => it.type) || []
      );
    },
    [config]
  );

  return {
    info,
    getAggregates,
  };
};
