import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import useNavigation from 'utils/useNavigation';
import timeRangeSlice, { TimeRangeState } from './reducer';

export const useTimeRange = () => {
  const { start, end, summaryPageStart, summaryPageEnd } = useSelector<
    RootState,
    TimeRangeState
  >((state) => {
    return state.timeRange;
  });

  const dispatch = useDispatch();
  const { isSummaryPage } = useNavigation();

  const setTimeRange = useCallback(
    (newStart: number, newEnd: number) => {
      // Summary Page has its own time range state since it is the only one we're
      // limiting date selection for now due to the amount of data we are going to load
      if (isSummaryPage) {
        dispatch(
          timeRangeSlice.actions.adjustSummaryPage({
            start: newStart,
            end: newEnd,
          })
        );
      } else {
        dispatch(
          timeRangeSlice.actions.adjust({ start: newStart, end: newEnd })
        );
      }
    },
    [dispatch, isSummaryPage]
  );

  const slide = (direction: 'forwards' | 'backwards') => {
    dispatch(timeRangeSlice.actions.slide(direction));
  };

  return {
    start: isSummaryPage ? summaryPageStart : start,
    end: isSummaryPage ? summaryPageEnd : end,
    setTimeRange,
    slideTimeWindow: slide,
  };
};
