export enum METRICS {
  WellSummaryDataFetching = 'WELL_SUMMARY_DATA_FETCHING',
  GroupCreation = 'DEVIATION_GROUP_GROUP_CREATION',
  GroupUpdate = 'DEVIATION_GROUP_GROUP_UPDATE',
  GroupFormUpdate = 'DEVIATION_GROUP_GROUP_FORM_UPDATE',
  GroupDeletion = 'DEVIATION_GROUP_GROUP_DELETION',
  GroupWellSummaryUpdate = 'DEVIATION_GROUP_WELL_SUMMARY_UPDATE',
  CommentCreation = 'COMMENTS_COMMENT_CREATION',
  CommentUpdate = 'COMMENTS_COMMENT_UPDATE',
  CommentDeletion = 'COMMENTS_COMMENT_DELETION',
  AcknowledgeNotification = 'NOTIFICATION_ACKNOWLEDGE_NOTIFICATION',
}
