import { CogniteEvent, EventFilter } from '@cognite/sdk';
import { SIDECAR } from 'utils/sidecar';

import { getClient } from 'utils/cognitesdk';
import { PropsToEdit } from './types';

const { bestdayApiBaseUrl } = SIDECAR;
const DEVIATIONS_ENDPOINT = new URL('deviations', bestdayApiBaseUrl).href;

export const fetchDeviationGroups = async (
  customFilter: Partial<EventFilter>,
  project: string
) => {
  const groups = await getClient().post(`${DEVIATIONS_ENDPOINT}/filter`, {
    withCredentials: true,
    data: {
      filter: customFilter,
    },
    headers: {
      'x-cdp-project': project,
    },
  });

  return groups.data;
};

export const editGroup = async (
  groupId: string,
  updateProps: PropsToEdit,
  project: string
): Promise<CogniteEvent[]> => {
  const updatedEvent = await getClient().post(`${DEVIATIONS_ENDPOINT}/edit`, {
    withCredentials: true,
    data: {
      groupId,
      propsToEdit: updateProps,
    },
    headers: {
      'x-cdp-project': project,
    },
  });

  return updatedEvent.data;
};
