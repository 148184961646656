import { useCallback, useEffect, useMemo } from 'react';
import { reportException } from '@cognite/react-errors';
import {
  Deferment,
  applyUnitConversionToDeferments,
  updateDeferment,
} from 'utils/models/deferments';
import { useTimeRange } from 'features/timeRange';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useDispatch, useSelector } from 'react-redux';
import defermentsSlice from 'features/deferments';
import { RootState } from 'store';
import { useUnitConversion } from 'features/unitConversion';
import { useEventsBDVolume } from 'utils/models/events';
import { useProjectContext } from 'containers/AuthContainer';
import useConfig from 'hooks/useConfig';
import { useCollections } from 'features/collections';
import {
  GetDefermentsParams,
  getDeferments,
  useTemplatesQuery,
} from 'hooks/useGraphQlQuery';
import { DefermentsState } from './reducer';

/**
 * @param alternativeUnit if passed, a `convertedDeferments` property will be returned
 */

type UseDefermentsOptions = {
  alternativeUnit?: string;
};

export const useDeferments = ({
  alternativeUnit,
}: UseDefermentsOptions = {}) => {
  const { selectedAsset } = useCurrentAsset();
  const { rootAssetConfig } = useConfig();
  const { getCurrentCollection } = useCollections();
  const { convertValue } = useUnitConversion();
  const { start, end } = useTimeRange();
  const dispatch = useDispatch();

  const { deferments, convertedDeferments, loading, error } = useSelector<
    RootState,
    DefermentsState
  >((state) => state.deferments);

  const externalIds = useMemo(() => {
    // If current asset is undefined, then it means we're in the favorites (wells) mode
    return selectedAsset
      ? [selectedAsset.externalId!]
      : getCurrentCollection()?.favorites.map((fav) => fav.externalId) || [];
  }, [getCurrentCollection, selectedAsset]);

  const level = useMemo(() => {
    return !selectedAsset || selectedAsset.networkLevel === 'Well'
      ? 'well'
      : 'system';
  }, [selectedAsset]);

  const { data } = useTemplatesQuery<Array<Deferment>, GetDefermentsParams>(
    {
      fn: (params: GetDefermentsParams) => {
        dispatch(defermentsSlice.actions.getDeferments());
        return getDeferments(params);
      },
      key: `getDeferments-${JSON.stringify(externalIds)}`,
    },
    {
      externalIds,
      level,
      templateInfo: rootAssetConfig?.templates!,
      start,
      end,
      preferredUnit: alternativeUnit,
    },
    {
      enabled: !!rootAssetConfig?.templates && externalIds.length > 0,
      onError: (err) => {
        reportException(err);
        dispatch(
          defermentsSlice.actions.getDefermentsError({
            error: err,
          })
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      const deferments = applyUnitConversionToDeferments(
        data,
        convertValue,
        alternativeUnit
      );

      if (alternativeUnit) {
        dispatch(
          defermentsSlice.actions.getConvertedDefermentsSuccess({
            deferments,
          })
        );
      } else {
        dispatch(
          defermentsSlice.actions.getDefermentsSuccess({
            deferments: deferments.map((deferment) => {
              const volumeInCommonUnit = deferment.volumes
                .map(({ product, value, unit }) =>
                  convertValue({
                    product,
                    value,
                    sourceUnit: unit,
                    targetUnit: 'BOE',
                    skipRateConversion: true,
                  })
                )
                .reduce(
                  (acc: number, convertedValue) =>
                    acc + (convertedValue?.value || 0),
                  0
                );
              return { ...deferment, volumeInCommonUnit };
            }),
          })
        );
      }
    }
  }, [alternativeUnit, convertValue, data, dispatch]);

  const { bestDayVolumes } = useEventsBDVolume(
    useMemo(
      () =>
        deferments
          .map((deferment) => {
            return {
              eventExternalId: deferment.externalId,
              product: 'HYDROCARBON',
              volume: deferment.volumeInCommonUnit || 0,
              start: +new Date(deferment.startTime),
              end: deferment.endTime ? +new Date(deferment.endTime) : 0,
            };
          })
          .flat(),
      [deferments]
    )
  );

  return { loading, error, deferments, convertedDeferments, bestDayVolumes };
};

export const useSelectedDeferment = () => {
  const { selectedDefermentExternalId } = useSelector<
    RootState,
    DefermentsState
  >((state) => {
    return state.deferments;
  });
  const dispatch = useDispatch();

  const setSelectedDefermentExternalId = useCallback(
    (externalId?: string) => {
      dispatch(
        defermentsSlice.actions.setSelectedDeferment({
          selectedDefermentExternalId: externalId,
        })
      );
    },
    [dispatch]
  );
  return { selectedDefermentExternalId, setSelectedDefermentExternalId };
};

export const useUpdateDeferment = () => {
  const { updateLoading, updateError } = useSelector<
    RootState,
    DefermentsState
  >((state) => state.deferments);

  const dispatch = useDispatch();
  const { project } = useProjectContext();

  const { rootAssetConfig } = useConfig();

  const updateDefermentFacility = (
    base: Deferment,
    facility: { name: string; externalId: string }
  ) => {
    dispatch(defermentsSlice.actions.updateEventStart());

    updateDeferment({
      base,
      project,
      patch: { facility },
      datasetExternalId: rootAssetConfig?.dataSetMapping.DEFERMENTS || '',
    })
      .then((updatedDeferment) => {
        dispatch(
          defermentsSlice.actions.updateEventSuccess({
            updatedDeferment,
          })
        );
      })
      .catch((ex) => {
        reportException(ex);
        dispatch(
          defermentsSlice.actions.updateEventError({
            updateError: ex,
          })
        );
      });
  };

  return { updateDefermentFacility, updateLoading, updateError };
};
