/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import dayjs from 'dayjs';
import BestDay from 'app/BestDay';
import {
  Container,
  ConditionalWrapper,
  AuthConsumer,
} from '@cognite/react-container';
import { QueryClient, QueryClientProvider } from 'react-query';
import { getTenant } from 'utils/tenancy';
import store from 'store';
import { ErrorWatcher } from '@cognite/react-errors';
import AppProviders from 'containers/AppProviders';
import ClusterCheckContainer from 'containers/ClusterCheckContainer';
import config from 'utils/config';
import LayerLookup from 'utils/zindex';
import { SIDECAR } from 'utils/sidecar';
import { withI18nSuspense } from '@cognite/react-i18n';
import AuthContainer from 'containers/AuthContainer';
import PermissionsCheckContainer from 'containers/PermissionsCheckContainer';
import UnleashProvider from './UnleashProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: dayjs.duration(1, 'hour').asMilliseconds(),
    },
  },
});

const clusters = [
  {
    label: 'Single customer environments',
    options: [{ value: '', label: 'Europe 1 (Google)' }],
  },
];

const App = () => {
  const tenant = getTenant();

  return (
    <>
      <Container
        sidecar={{
          disableSentry: true,
          ...(SIDECAR as any),
          disableIntercom: true,
          disableLegacyLogin: true,
          availableClusters: clusters,
        }}
      >
        <>
          <ConditionalWrapper
            condition={!!SIDECAR.unleash}
            wrap={UnleashProvider}
          >
            <AuthConsumer>
              {(authState) => {
                if (!authState || !authState.authState?.authenticated) {
                  return null;
                }

                return (
                  <AppProviders store={store} tenant={tenant}>
                    <AuthContainer>
                      <ClusterCheckContainer>
                        <QueryClientProvider client={queryClient}>
                          <PermissionsCheckContainer>
                            <BestDay />
                          </PermissionsCheckContainer>
                        </QueryClientProvider>
                      </ClusterCheckContainer>
                    </AuthContainer>
                  </AppProviders>
                );
              }}
            </AuthConsumer>
          </ConditionalWrapper>
          {config.errors.mode !== 'off' && config.env === 'development' && (
            <ErrorWatcher
              zIndex={LayerLookup.ERROR_WATCHER}
              errorMode={config.errors.mode}
              strict={config.errors.strict}
            />
          )}
        </>
      </Container>
    </>
  );
};

export default withI18nSuspense(App);
