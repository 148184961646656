import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import collectionsSlice from 'features/collections';
import {
  Collection,
  deleteCollection,
  Favorite,
  fetchCollections,
  insertCollection,
  updateCollection,
} from 'utils/models/collections';
import { useProjectContext } from 'containers/AuthContainer';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import useNavigation from 'utils/useNavigation';
import { useParams } from 'react-router';
import { reportException } from '@cognite/react-errors';
import { Body, toast } from '@cognite/cogs.js';
import { Trans, useTranslation } from '@cognite/react-i18n';
import createToastOptions from 'utils/toasts/createToastOptions';
import { getActiveCollection, getCollections } from './selectors';

// Custom hook for handling collections
export const useCollections = () => {
  const dispatch = useDispatch();
  const { project } = useProjectContext();
  const collections = useSelector(getCollections);
  const activeCollection = useSelector(getActiveCollection);
  const { rootAsset } = useCurrentAsset();
  const { isCollectionPage } = useNavigation();
  const { collectionId = null } =
    useParams<{ collectionId: string | undefined }>();

  useEffect(() => {
    if (collectionId) {
      if (collectionId !== activeCollection?.key) {
        dispatch(
          collectionsSlice.actions.setActiveCollectionId({
            collectionKey: collectionId,
          })
        );
      }
    }
  }, [activeCollection, collectionId, dispatch]);

  const getCurrentCollection = useCallback(() => {
    if (!isCollectionPage || !activeCollection) {
      return null;
    }
    return activeCollection;
  }, [isCollectionPage, activeCollection]);

  const addCollection = useCallback(
    async (name: string, favorites: Favorite[]) => {
      const insertedCollection: Collection = await insertCollection(
        name,
        favorites,
        rootAsset?.externalId ?? '',
        project
      );
      dispatch(
        collectionsSlice.actions.add({ collection: insertedCollection })
      );
    },
    [dispatch, project, rootAsset]
  );

  const editCollection = useCallback(
    async (collection: Collection) => {
      await updateCollection(collection, project);
      dispatch(collectionsSlice.actions.edit({ collection }));
    },
    [dispatch, project]
  );

  const removeCollection = useCallback(
    async (collection: Collection) => {
      await deleteCollection(collection.key, project);
      dispatch(collectionsSlice.actions.delete({ collection }));
    },
    [dispatch, project]
  );

  const batchEditCollections = useCallback(
    async (collections: Collection[]) => {
      if (!rootAsset?.externalId) {
        throw new Error('No root asset defined');
      }

      for (let i = 0; i < collections.length; i++) {
        const collection = collections[i];
        // We want to perform an async operation per collection here.
        // eslint-disable-next-line no-await-in-loop
        await updateCollection(collection, project);
      }
      dispatch(collectionsSlice.actions.batchEdit({ collections }));
    },
    [dispatch, project, rootAsset?.externalId]
  );

  return {
    activeCollection,
    collections,
    getCurrentCollection,
    removeCollection,
    addCollection,
    editCollection,
    batchEditCollections,
  };
};

export const useInitializeCollections = () => {
  const { project } = useProjectContext();
  const dispatch = useDispatch();
  const { t } = useTranslation('CollectionsTree', { useSuspense: true });

  const initializeCollections = useCallback(
    (rootAssetExternalId: string) => {
      fetchCollections(rootAssetExternalId, project)
        .then((collections) => {
          dispatch(
            collectionsSlice.actions.get({
              collections,
            })
          );
        })
        .catch((error) => {
          reportException(error).then((sentryException) => {
            toast.error(
              <Body level={2}>
                <Trans t={t} i18nKey="toast-error-fetchCollections">
                  Could not fetch collections{' '}
                  {{ errorId: sentryException?.errorId ?? '' }}
                </Trans>
              </Body>,
              createToastOptions()
            );
          });
        });
    },
    [dispatch, project, t]
  );

  return {
    initializeCollections,
  };
};
