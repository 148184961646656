import { useState, useEffect } from 'react';
import { reportException } from '@cognite/react-errors';
import { Asset } from '@cognite/sdk';
import { ParentAssetLabel } from 'utils/models/systems';
import { getAsset } from 'utils/apicache';
import isString from 'lodash/isString';
import { useQuery } from 'react-query';
import { getParentAssets, getRootAssets } from './api';

export type UseParentAssets = (
  childIdOrExternalId: string | number,
  networkLevelLabel: ParentAssetLabel[],
  searchOption?: 'containsAll' | 'containsAny'
) => { parentAssets: Asset[]; loading: boolean };

export const useParentAssets: UseParentAssets = (
  childIdOrExternalId,
  networkLevelLabel,
  searchOption: 'containsAll' | 'containsAny' = 'containsAll'
) => {
  const [parentAssets, setParentAssets] = useState<Asset[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };

    if (!childIdOrExternalId) {
      return cancel;
    }

    const fetchData = async () => {
      setLoading(true);
      let childExternalId = '';
      try {
        if (isString(childIdOrExternalId)) {
          childExternalId = childIdOrExternalId;
        } else {
          const asset = await getAsset({
            id: childIdOrExternalId,
          });
          childExternalId = asset.externalId || '';
        }

        const assets = await getParentAssets(
          childExternalId,
          networkLevelLabel,
          searchOption
        );
        if (canceled) {
          return;
        }
        setParentAssets(assets);
      } catch (ex) {
        reportException(ex, { childAssetExternalId: childExternalId });
      } finally {
        if (!canceled) {
          setLoading(false);
        }
      }
    };

    if (!canceled) {
      fetchData();
    }

    return cancel;
  }, [childIdOrExternalId, networkLevelLabel, searchOption]);

  return { parentAssets, loading };
};

export const useRootAssets = () =>
  useQuery<Asset[]>('Root Asset List', () => getRootAssets(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 5,
    onError: (err) => {
      reportException(err as Error);
    },
  });
