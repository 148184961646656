import dayjs from 'dayjs';

export const getStartOfDay = (date: Date | number) =>
  dayjs(date)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate();

export const getStartOfMonth = (date: Date | number) =>
  dayjs(date)
    .set('date', 1)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate();

export const getEndOfDay = (date: Date | number) =>
  dayjs(date).set('hour', 23).set('minute', 59).set('second', 59).toDate();

/*
 * Returns the start of the current day in local time in UTC
 *
 * To be used when you need the start of the current day in UTC
 * but want to avoid "rolling over" to the previous/next day in case of
 * timezone differences between local time and UTC.
 *
 * Example: If local timezone is UTC+7, and the input date is "Jan 1. 23:00 UTC+7"
 * the output will be "Jan 1. 00:00 UTC+0"
 * (Not Jan 2. 00:00 UTC+0 which you would get from converting to UTC before adjusting)
 */
export const getStartOfLocalDay = (date: Date | number) => {
  const standardizedDate = new Date(date);
  return new Date(
    Date.UTC(
      standardizedDate.getFullYear(),
      standardizedDate.getMonth(),
      standardizedDate.getDate()
    )
  );
};

// Same as getStartOfLocalDay except returns 23:59 of date in UTC
export const getEndOfLocalDay = (date: Date | number) => {
  const standardizedDate = new Date(date);
  return new Date(
    Date.UTC(
      standardizedDate.getFullYear(),
      standardizedDate.getMonth(),
      standardizedDate.getDate(),
      23,
      59,
      59
    )
  );
};
