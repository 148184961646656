/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const timeRangeSlice = createSlice({
  name: 'timeRange',
  initialState: {
    start: 0,
    end: 0,
    summaryPageStart: 0,
    summaryPageEnd: 0,
  },
  reducers: {
    adjust: (
      state,
      { payload: { start, end } }: PayloadAction<{ start: number; end: number }>
    ) => {
      state.start = start;
      state.end = end;
    },

    adjustSummaryPage: (
      state,
      { payload: { start, end } }: PayloadAction<{ start: number; end: number }>
    ) => {
      state.summaryPageStart = start;
      state.summaryPageEnd = end;
    },

    slide: (
      state,
      { payload: direction }: PayloadAction<'forwards' | 'backwards'>
    ) => {
      let delta = state.end - state.start;
      if (direction === 'backwards') {
        delta *= -1;
      }
      state.start += delta;
      state.end += delta;
    },
  },
});

export type TimeRangeState = ReturnType<typeof timeRangeSlice.reducer>;

export default timeRangeSlice;
