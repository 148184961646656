import React, {
  createContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  useReducer,
  Dispatch,
} from 'react';
import { Asset } from '@cognite/sdk';
import { getAsset } from 'utils/apicache';
import { reportException } from '@cognite/react-errors';
import { Metrics, useMetrics } from '@cognite/metrics';
import { useLocation } from 'react-router-dom';
import retry from 'async-retry';
import {
  Well,
  buildWell,
  isWell,
  getNetworkLevel,
  getParentAssets,
  NetworkLevel,
  useRootAssets,
} from 'utils/models/systems';
import useNavigation from 'utils/useNavigation';
import isString from 'lodash/isString';
import { getProductConfig, getProducts } from 'hooks/useGraphQlQuery';
import { Pages } from 'utils/models/enums';
import { usePreferences } from 'features/preferences';
import useInitializeAssetTree from 'features/assetTree/useInitalizeAssetTree';
import { useInitializeCollections } from 'features/collections';
import head from 'lodash/head';
import { RootAsset } from 'hooks/types';
import { getRootAssetConfig } from 'utils/rootAssetConfig';
import { useQuery } from 'react-query';
import useChartSelectionState from 'components/Chart/hooks/useChartSelectionState';
import { useHoveredDeviation, useSelectedDeviation } from 'features/deviations';
import {
  productsReducer,
  Products,
  ProductActions,
  ProductsState,
} from './productsReducer';
import {
  getCorrectRootAssetConfig,
  getLowerMostRootAssetParent,
  isAssetRelatedToSelectedAsset,
} from './utils';

export type BestDayAsset = Asset & {
  parent?: BestDayAsset;
  networkLevel: NetworkLevel;
  templateLevel?: 'system' | 'well';
};

export type SelectedAssetContext = {
  selectedAsset?: BestDayAsset;
  selectedWell?: Well;
  setSelectedAsset: (assetOrExternalId: Asset | string) => void;
  rootAsset?: Asset;
  allProducts: Products;
  products: string[]; // This is the selected products based on allProducts
  loaded: boolean;
  loading: boolean;
  error: Error | undefined;
  productsDispatch: Dispatch<ProductActions>;
  rootAssetConfig?: RootAsset;
  setRootAsset: (nextRootAsset: Asset) => void;
};

export const Context = createContext<SelectedAssetContext>({
  setSelectedAsset: () => undefined,
  products: [],
  allProducts: {},
  loaded: false,
  loading: false,
  error: undefined,
  productsDispatch: () => null,
  setRootAsset: () => null,
});

type Props = {
  children: React.ReactNode;
};

const initialProductsState: ProductsState = {
  allProducts: {},
  products: [],
};

const retryOptions: retry.Options = {
  retries: 5,
  factor: 1,
};

const CurrentAssetProvider = ({ children }: Props) => {
  const metrics = useMetrics('CurrentAssetProvider');
  const { currentPage, navigate, isSummaryPage, isCollectionPage } =
    useNavigation();
  const {
    preferences: { lastSelectedRootAsset },
    setPreferences,
  } = usePreferences();

  const { resetChartSelection } = useChartSelectionState();

  const { setSelectedDeviationExternalId } = useSelectedDeviation();

  const { initializeCollections } = useInitializeCollections();

  const { setHoveredDeviation } = useHoveredDeviation();

  const { pathname } = useLocation();

  const assetExternalId =
    pathname.split('/')[2] && !isCollectionPage
      ? decodeURIComponent(pathname.split('/')[2])
      : '';

  const [loaded, setLoaded] = useState(false);

  const [
    { rootAsset, rootAssetConfig, selectedAsset },
    setCurrentAssetProvider,
  ] = useState<{
    rootAsset: Asset | undefined;
    rootAssetConfig: RootAsset | undefined;
    selectedAsset: BestDayAsset | undefined;
  }>({
    rootAsset: undefined,
    rootAssetConfig: undefined,
    selectedAsset: undefined,
  });

  const { data: rootAssets } = useRootAssets();

  // find correct rootasset w.r.t asset
  const getCorrectRootAsset = useCallback(
    (
      currentRootAsset: Asset | undefined,
      newAsset: BestDayAsset | undefined
    ) => {
      if (
        !newAsset ||
        isAssetRelatedToSelectedAsset(currentRootAsset?.externalId, newAsset)
      )
        return currentRootAsset;

      const externalId = getLowerMostRootAssetParent(newAsset)?.externalId;

      if (!externalId)
        throw new Error(`Unable to find top level asset for ${externalId}`);

      return rootAssets?.find(
        (rootAsset) =>
          rootAsset.externalId === externalId ||
          (newAsset?.parentExternalId &&
            rootAsset?.externalId === newAsset?.parentExternalId)
      );
    },
    [rootAssets]
  );

  const setSelectedAsset = useCallback(
    (asset: BestDayAsset | undefined) => {
      setCurrentAssetProvider((pv) => {
        const rootAsset = getCorrectRootAsset(pv.rootAsset, asset);

        return {
          rootAsset,
          rootAssetConfig: getCorrectRootAssetConfig(pv.rootAssetConfig, asset),
          selectedAsset: asset,
        };
      });
    },
    [getCorrectRootAsset]
  );

  const setRootAsset = useCallback((asset: Asset | undefined) => {
    const config =
      asset && asset.externalId
        ? getRootAssetConfig(asset.externalId)
        : undefined;

    setCurrentAssetProvider({
      selectedAsset: undefined,
      rootAsset: asset,
      rootAssetConfig: config,
    });
  }, []);

  const [selectedWell, setSelectedWell] = useState<Well>();

  const [productsState, productsDispatch] = useReducer(
    productsReducer,
    initialProductsState
  );

  const templateConfig = useMemo(() => {
    return rootAssetConfig?.templates;
  }, [rootAssetConfig]);

  useInitializeAssetTree({
    rootAssetExternalId: rootAsset?.externalId,
    templateInfo: templateConfig,
  });

  const updateSelectedAsset = useCallback(
    (asset: BestDayAsset | undefined) => {
      setSelectedAsset(asset);
      if (asset && isWell(asset)) {
        setSelectedWell(buildWell(asset));
      } else {
        setSelectedWell(undefined);
      }
    },
    [setSelectedAsset]
  );

  // Force refresh asset tree on rootAsset change
  useEffect(() => {
    if (!rootAsset?.externalId || !templateConfig) {
      return;
    }
    initializeCollections(rootAsset?.externalId);
  }, [rootAsset, initializeCollections, templateConfig]);

  const navigateToAsset = useCallback(
    (assetOrExternalId: Asset | string): void => {
      const externalId = isString(assetOrExternalId)
        ? assetOrExternalId
        : assetOrExternalId.externalId;

      if (!externalId) {
        throw new Error('External IDs are required');
      }

      resetChartSelection();
      setSelectedDeviationExternalId();
      setHoveredDeviation();

      if (
        currentPage === Pages.DeepDive ||
        (!isString(assetOrExternalId) &&
          getNetworkLevel(assetOrExternalId) === 'Well')
      ) {
        navigate({
          page: Pages.DeepDive,
          id: externalId,
        });
      } else {
        navigate({
          page: isSummaryPage ? Pages.Wells : Pages.KPI,
          id: externalId,
        });
      }
    },
    [
      currentPage,
      isSummaryPage,
      navigate,
      resetChartSelection,
      setHoveredDeviation,
      setSelectedDeviationExternalId,
    ]
  );

  // recursively update asset parents
  const updateAssetParent = useCallback(
    async (asset: Asset): Promise<BestDayAsset> => {
      if (
        selectedAsset &&
        selectedAsset.parent &&
        asset.externalId === selectedAsset.parent.externalId
      ) {
        return selectedAsset.parent;
      }

      const networkLevel = getNetworkLevel(asset);

      const assetWithNetworkLevel = {
        ...asset,
        networkLevel,
      };

      if (!asset.externalId) {
        return assetWithNetworkLevel;
      }

      const isRoot = lastSelectedRootAsset === asset.externalId;

      const fetchParentAsset = async (externalId: string) => {
        try {
          return await getParentAssets(
            externalId,
            [
              'NETWORK_LEVEL_COUNTRY',
              'NETWORK_LEVEL_TOP_LEVEL_ASSET',
              'NETWORK_LEVEL_PRODUCTION_SYSTEM',
              'NETWORK_LEVEL_PRODUCTION_SUBSYSTEM',
            ],
            'containsAny'
          );
        } catch (error) {
          // Some users do not have access to country asset, that is ok.
          if ((error as { status: number }).status === 403) {
            if (
              [
                'NETWORK_LEVEL_COUNTRY',
                'NETWORK_LEVEL_TOP_LEVEL_ASSET',
              ].includes(networkLevel)
            )
              throw new Error(
                `Unable to find top level asset for ${asset.externalId}`
              );
            return [];
          }

          throw error;
        }
      };

      const [parent] = isRoot ? [] : await fetchParentAsset(asset.externalId);

      if (!parent) {
        return assetWithNetworkLevel;
      }

      if (
        selectedAsset &&
        selectedAsset.parent &&
        parent.externalId === selectedAsset.parent.externalId
      ) {
        return {
          ...assetWithNetworkLevel,
          parent: selectedAsset.parent,
        };
      }

      return {
        ...assetWithNetworkLevel,
        parent: isRoot ? undefined : await updateAssetParent(parent),
      };
    },
    [lastSelectedRootAsset, selectedAsset]
  );

  // When the URL changes, fetch newly selected asset.
  const {
    isLoading: loadingAsset,
    error: assetError,
    data: fetchedAsset,
  } = useQuery(
    ['fetchAsset', assetExternalId],
    async () => {
      if (!assetExternalId) {
        setLoaded(false);
        return undefined;
      }

      const timer = metrics.start('loadAsset', {
        assetExternalId,
      });

      const asset = await updateAssetParent(
        await getAsset({
          externalId: assetExternalId,
        })
      );

      Metrics.stop(timer);
      return asset;
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  // set newly selected asset after fetching data
  useEffect(() => {
    updateSelectedAsset(fetchedAsset);
  }, [fetchedAsset, updateSelectedAsset]);

  // Fetch product list when a new asset is selected
  const { isLoading: loadingProducts, error: fetchingProductError } = useQuery(
    ['fetchProducts', selectedAsset?.externalId],
    async () => {
      if (!selectedAsset) {
        return undefined;
      }
      const { externalId } = selectedAsset;

      if (!externalId)
        throw new Error(`External Id is missing for ${selectedAsset.id}`);

      let products: string[] = [];
      // Pull other information about the asset
      if (templateConfig) {
        const networkLevel = getNetworkLevel(selectedAsset!);
        const level = networkLevel === 'Well' ? 'well' : 'system';
        const allProducts = await retry(
          // Temporary retry until sdk supports retry by default https://github.com/cognitedata/cognite-sdk-js/pull/705
          () => {
            return getProductConfig({
              templateInfo: templateConfig,
            });
          },
          {
            ...retryOptions,
            onRetry: (e, attempt) => {
              // eslint-disable-next-line no-console
              console.log(
                `retrying useQuery1 after ${e}... (attempt ${attempt})`
              );
            },
          }
        );
        products = (
          await retry(
            // Temporary retry until sdk supports retry by default https://github.com/cognitedata/cognite-sdk-js/pull/705
            () => {
              return getProducts({
                externalId,
                level,
                products: allProducts.map((product) => product.type),
                templateInfo: templateConfig,
              });
            },
            {
              ...retryOptions,
              onRetry: (e, attempt) => {
                // eslint-disable-next-line no-console
                console.log(
                  `retrying useQuery2 after ${e}... (attempt ${attempt})`
                );
              },
            }
          )
        ).map((it) => it.type);
      }

      return products;
    },
    {
      onSuccess: (products) => {
        if (products) {
          productsDispatch({
            type: 'SET_ALL_PRODUCTS',
            payload: {
              products: [...products, 'HYDROCARBON'], // temporary add this as products
            },
          });
        }
      },
      onError: (e) => {
        reportException(e as Error, {
          assetExternalId: selectedAsset?.externalId,
        });
      },
    }
  );

  // Set preferred rootasset when no asset is selected.
  useEffect(() => {
    // we have assetExternalId, and rootasset will be determined based on that
    if (assetExternalId) return;

    if (rootAsset || !rootAssets) {
      return;
    }

    if (!lastSelectedRootAsset && rootAssets.length > 1) {
      return;
    }

    const firstAvailableRootAsset = head(rootAssets)!;

    const preferredRootAsset = rootAssets.find(
      (asset) => asset.externalId === lastSelectedRootAsset
    );

    // If user has access to only one asset, then set asset as preferred root asset
    // or
    // if user lost access to previously set root asset, then set the first available
    // asset as the new root asset
    if (rootAssets.length === 1 || !preferredRootAsset) {
      setPreferences(
        {
          lastSelectedRootAsset: firstAvailableRootAsset.externalId,
        },
        () => {
          navigateToAsset(firstAvailableRootAsset);
        }
      );

      return;
    }

    setRootAsset(preferredRootAsset);
  }, [
    assetExternalId,
    lastSelectedRootAsset,
    navigateToAsset,
    rootAsset,
    rootAssets,
    setPreferences,
    setRootAsset,
  ]);

  const render = useMemo(() => {
    const contextValue = {
      selectedAsset,
      selectedWell,
      setSelectedAsset: navigateToAsset,
      rootAsset,
      loading: loadingAsset || loadingProducts,
      loaded,
      error: (assetError || fetchingProductError) as Error | undefined,
      rootAssetConfig,
      ...productsState,
    };

    return (
      <Context.Provider
        value={{
          ...contextValue,
          productsDispatch,
          setRootAsset,
        }}
      >
        {children}
      </Context.Provider>
    );
  }, [
    assetError,
    children,
    fetchingProductError,
    loaded,
    loadingAsset,
    loadingProducts,
    navigateToAsset,
    productsState,
    rootAsset,
    rootAssetConfig,
    selectedAsset,
    selectedWell,
    setRootAsset,
  ]);

  return render;
};

export default CurrentAssetProvider;
