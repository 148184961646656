import React from 'react';
import { Body, Flex } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import Duration from './Duration';
import LastUpdatedOn from './LastUpdatedOn';

export type Props = {
  startDate: Date;
  endDate: Date | undefined;
  lastUpdatedTime: Date;
  showOnlyStart?: boolean;
};

const CardHeader = ({
  startDate,
  endDate,
  lastUpdatedTime,
  showOnlyStart,
}: Props) => {
  return (
    <CardHeaderContainer>
      <StyledBody level={2} strong>
        <Duration
          startDate={startDate}
          endDate={endDate}
          showOnlyStart={showOnlyStart}
        />{' '}
      </StyledBody>
      {lastUpdatedTime && (
        <LightBody level={2}>
          <LastUpdatedOn lastUpdatedTime={lastUpdatedTime} />
        </LightBody>
      )}
    </CardHeaderContainer>
  );
};

const CardHeaderContainer = styled(Flex)`
  justify-content: space-between;
`;
const StyledBody = styled(Body)`
  color: var(--cogs-greyscale-grey7);
`;

const LightBody = styled(Body)`
  color: var(--cogs-greyscale-grey6);
`;

export default CardHeader;
