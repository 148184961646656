import dayjs from 'dayjs';
import {
  EventDurationFilter,
  EventVolumeFilter,
} from 'features/preferences/filter';
import { DeviationType } from 'utils/models';

export const filterByDuration = (
  value: EventDurationFilter[],
  startTime: number,
  endTime?: number
) => {
  const [min, max] = value;
  const topLimit = max === '>30' ? Number.MAX_SAFE_INTEGER : +max;

  const oneDayDuration = dayjs.duration(1, 'day').asMilliseconds();
  const today = new Date();
  const durationInDays = ((endTime || +today) - startTime) / oneDayDuration;

  const minLimit = min === '<1' ? 0 : +min;

  return minLimit <= durationInDays && durationInDays <= topLimit;
};

export const filterByPerformance = (
  filterValue: DeviationType[],
  volume: number
) => {
  const isUnderPerformanceSelected = filterValue.includes('underperformance');
  const isOverPerformanceSelected = filterValue.includes('overperformance');

  if (isUnderPerformanceSelected) return volume > 0;
  if (isOverPerformanceSelected) return volume <= 0;

  return true;
};

export const filterByVolume = (
  value: EventVolumeFilter[],
  fractionOfBestDay?: number,
  eventVolume?: number,
  bestDayVolume?: number
) => {
  const [min, max] = value;
  let volumeRation = 0;
  if (fractionOfBestDay) {
    volumeRation = fractionOfBestDay * 100;
  } else if (eventVolume && bestDayVolume) {
    volumeRation = (eventVolume / bestDayVolume) * 100;
  }

  return (
    +min.replace(/%/, '') <= volumeRation &&
    volumeRation <= +max.replace(/%/, '')
  );
};

export const durationChipTitle = (
  min: EventDurationFilter,
  max: EventDurationFilter,
  dayAbbreviationUnit: string
) => {
  if (min === max) {
    return `${max}${dayAbbreviationUnit}`;
  }
  if (min === '<1') {
    return `<=${max}${dayAbbreviationUnit}`;
  }
  if (max.includes('>')) {
    return `>=${min}${dayAbbreviationUnit}`;
  }
  return `${min}${dayAbbreviationUnit} - ${max}${dayAbbreviationUnit}`;
};
