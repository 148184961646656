import { useEffect, useState } from 'react';
import { useTimeRange } from 'features/timeRange';
import { CogniteEvent, DatapointAggregate, EventFilter } from '@cognite/sdk';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { reportException } from '@cognite/react-errors';
import { useTranslation } from '@cognite/react-i18n';
import { Option } from 'components/SortDropdown';
import {
  filterByDuration,
  filterByPerformance,
  filterByVolume,
} from 'utils/filter';
import { useUnitConversion } from 'features/unitConversion';
import dayjs from 'dayjs';
import { getEndOfDay } from 'utils/datetime';
import isArray from 'lodash/isArray';
import { getBestDayTimeSeries } from 'hooks/useGraphQlQuery';
import useConfig from 'hooks/useConfig';
import { usePreferences } from 'features/preferences';
import {
  DeviationsFilter,
  DefermentsFilter,
  FilterKey,
  EventDurationFilter,
  EventVolumeFilter,
} from 'features/preferences/filter';
import { fetchEventBatch } from './api';
import { DeviationType } from '..';

export type UseEventBatch = {
  eventBatch: CogniteEvent[];
  loading: boolean;
  error?: Error;
};

export const useEventBatch = (externalId: string): UseEventBatch => {
  const { selectedAsset } = useCurrentAsset();
  const { start, end } = useTimeRange();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [eventBatch, setEventBatch] = useState<CogniteEvent[]>([]);

  useEffect(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };

    if (!externalId) {
      return cancel;
    }

    setLoading(true);
    const filter: EventFilter = {
      externalIdPrefix: externalId,
      activeAtTime: {
        min: start,
        max: end,
      },
    };
    if (selectedAsset) {
      filter.assetIds = [selectedAsset.id];
    }

    fetchEventBatch(filter)
      .then((batch) => {
        if (canceled) {
          return;
        }
        setEventBatch(batch);
      })
      .catch((err) => {
        if (canceled) {
          return;
        }
        reportException(err);
        setError(err);
      })
      .finally(() => {
        if (canceled) {
          return;
        }
        setLoading(false);
      });

    return cancel;
  }, [externalId, end, start, selectedAsset]);

  return {
    eventBatch,
    loading,
    error,
  };
};

type SortParamsProps = {
  volume: number;
  startDate: number;
  endDate?: number;
};

type SortEventsProps = {
  a: SortParamsProps;
  b: SortParamsProps;
};
export const useSortEventOptions = () => {
  const { t: tEventSort } = useTranslation('EventSort');

  const options = [
    {
      name: tEventSort('newest-sort', {
        defaultValue: 'Newest',
      }),
      sortValue: { order: 'DESC', column: 'eventTime' },
    },
    {
      name: tEventSort('oldest-sort', {
        defaultValue: 'Oldest',
      }),
      sortValue: { order: 'ASC', column: 'eventTime' },
    },
    {
      name: tEventSort('largest-volume-sort', {
        defaultValue: 'Largest Volume',
      }),
      sortValue: { order: 'DESC', column: 'eventVolume' },
    },
    {
      name: tEventSort('lowest-volume-sort', {
        defaultValue: 'Lowest Volume',
      }),
      sortValue: { order: 'ASC', column: 'eventVolume' },
    },
    {
      name: tEventSort('longest-duration-sort', {
        defaultValue: 'Longest Duration',
      }),
      sortValue: { order: 'DESC', column: 'eventDuration' },
    },
    {
      name: tEventSort('shortest-duration-sort', {
        defaultValue: 'Shortest Duration',
      }),
      sortValue: { order: 'ASC', column: 'eventDuration' },
    },
  ] as Option[];

  return { options };
};

export const useSortEvents = () => {
  const {
    preferences: {
      sideBar: { sortDropdown: sort },
    },
  } = usePreferences();
  const isDescOrder = sort?.order === 'DESC';

  const sortEvents = ({ a, b }: SortEventsProps) => {
    const startDateA = a.startDate;
    const startDateB = b.startDate;

    switch (sort?.column) {
      case 'eventTime': {
        const endDateA = a.endDate || Number.MAX_VALUE;
        const endDateB = b.endDate || Number.MAX_VALUE;
        const isEndDateComparable = endDateA !== endDateB;
        const eventTimeA = isEndDateComparable ? endDateA : startDateA;
        const eventTimeB = isEndDateComparable ? endDateB : startDateB;
        return isDescOrder ? eventTimeB - eventTimeA : eventTimeA - eventTimeB;
      }

      case 'eventVolume':
        return isDescOrder ? b.volume - a.volume : a.volume - b.volume;

      case 'eventDuration': {
        const endDateA = a.endDate || +new Date();
        const endDateB = b.endDate || +new Date();
        const durationA = endDateA - startDateA;
        const durationB = endDateB - startDateB;
        return isDescOrder ? durationB - durationA : durationA - durationB;
      }

      default:
        return 1;
    }
  };

  return { sortEvents };
};

export type UseFilterEvents = {
  startTime?: number;
  endTime?: number;
  status?: string;
  fractionOfBestDay?: number;
  boeVolume?: number;
  bestDayBoeVolume?: number;
  type?: string;
  event?: string;
  choke?: string;
  isOngoing?: boolean;
};

export const useFilterEvents = (
  filters: DeviationsFilter | DefermentsFilter,
  isDeviation?: boolean
) => {
  const filterEvents = ({
    startTime,
    endTime,
    status,
    fractionOfBestDay,
    boeVolume,
    bestDayBoeVolume,
    type,
    choke,
    isOngoing,
  }: UseFilterEvents) => {
    return Object.entries(filters || {})
      .map(([filterKey, filterValue]) => {
        if (isArray(filterValue) && filterValue.length > 0) {
          switch (filterKey as FilterKey) {
            case 'status': {
              return status && (filterValue as string[]).includes(status);
            }
            case 'type': {
              if (isDeviation) {
                return (
                  boeVolume &&
                  filterByPerformance(filterValue as DeviationType[], boeVolume)
                );
              }
              return type && (filterValue as string[]).includes(type);
            }
            case 'duration': {
              return filterByDuration(
                filterValue as EventDurationFilter[],
                startTime || 0,
                endTime
              );
            }
            case 'volume': {
              return filterByVolume(
                filterValue as EventVolumeFilter[],
                fractionOfBestDay,
                boeVolume,
                bestDayBoeVolume
              );
            }
            case 'choke': {
              return choke && (filterValue as string[]).includes(choke);
            }
            default:
              return true;
          }
        } else if (filterValue === true) {
          switch (filterKey as FilterKey) {
            case 'isOngoing': {
              return filters.isOngoing && isOngoing;
            }
            default:
              return true;
          }
        } else {
          return true;
        }
      })
      .every((el) => el);
  };

  return { filterEvents };
};

type UseEventsBDVolume = {
  eventExternalId: string;
  product: string;
  volume: number;
  start: number;
  end: number;
};

type BestDayVolumes = {
  [eventExternalId: string]: {
    [product: string]: number;
  };
};

export const useEventsBDVolume = (eventProps: UseEventsBDVolume[]) => {
  const { rootAssetConfig } = useConfig();
  const { convertedQuery } = useUnitConversion();
  const { selectedAsset } = useCurrentAsset();
  const [bestDayVolumes, setBestDayVolumes] = useState<BestDayVolumes>({});

  useEffect(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };

    const fetch = async () => {
      try {
        if (!selectedAsset?.externalId || !rootAssetConfig?.templates) {
          return;
        }

        const bestDayTimeSeries: { [key: string]: string } =
          await getBestDayTimeSeries({
            externalId: selectedAsset?.externalId!,
            templateInfo: rootAssetConfig?.templates!,
            level: selectedAsset?.networkLevel === 'Well' ? 'well' : 'system',
          });

        const withVolume = eventProps
          .map((eventProp) => {
            if (eventProp.start === eventProp.end) {
              return { ...eventProp, end: +getEndOfDay(eventProp.end) };
            }
            return eventProp;
          })
          .filter(({ volume }) => volume);

        const responses = await convertedQuery({
          items: withVolume
            .filter((it) => !!bestDayTimeSeries?.[it.product])
            .map(({ eventExternalId, product, start, end }) => ({
              requestId: `${eventExternalId}_${product}`,
              externalId: bestDayTimeSeries[product],
              toUnit: 'BOE',
              options: {
                start,
                end: end || +new Date(),
                aggregates: ['sum'],
                granularity: `${
                  dayjs(end || +new Date()).diff(dayjs(start), 'days') + 1
                }day`,
                limit: 1000,
              },
            })),
        });

        const volumes = responses.reduce((acc, resp, index) => {
          const { product } = withVolume[index];
          const { eventExternalId } = withVolume[index];
          const sum =
            resp && resp.datapoints?.length
              ? (resp.datapoints[0] as DatapointAggregate).sum
              : 0;

          return {
            ...acc,
            [eventExternalId]: {
              ...(acc[eventExternalId] || {}),
              [product]: sum || 0,
            },
          };
        }, {} as BestDayVolumes);
        setBestDayVolumes(volumes);
      } catch (ex) {
        reportException(ex);
      }
    };

    if (!canceled) {
      fetch();
    }

    return cancel;
  }, [convertedQuery, eventProps, selectedAsset, rootAssetConfig]);

  return { bestDayVolumes };
};
