import { Aggregate } from '@cognite/sdk';
import { VisibilityByProduct } from 'components/Chart/ChartTrendControls/types';
import { ReferenceType } from 'pages/WellDeepDive/hooks/useReferenceType';
import { DefermentType } from 'utils/models';
import { ChartPreferences } from './chartAxis';
import {
  sideBarFilterInitialState,
  wellSummaryFilterInitialState,
  SidebarFilter,
  WellSummaryFilter,
} from './filter';

export const Aggregations: Aggregate[] = ['sum', 'average'];
export const DefermentTypes: DefermentType[] = ['actual', 'future'];

export type SortOrder = 'DESC' | 'ASC';

export type SortState = {
  order: SortOrder;
  column: string;
};

export type UnitPreferences = {
  [unitGroupId: string]: string;
};

export enum ChartTypes {
  Production = 'production',
  ProductionTrends = 'productionTrends',
  ProductionEfficency = 'productionEfficency',
  Sensors = 'sensors',
  DeviationAndDeferments = 'deviationAndDeferment',
  Testing = 'testing',
}
export type TrendTypeVisibility = { [key: string]: boolean };
export type RatePreferences = 'Daily' | 'Hourly';

export type KPIReferenceLevel = 'system' | 'well';

export type KPIPreferences = {
  aggregation: Aggregate;
  selectedReference: {
    [key in KPIReferenceLevel]: ReferenceType;
  };
  defermentType: DefermentType;
  breakdownByProduct: Record<string, boolean>;
  selectedProduct: string;
  charts: {
    [ChartTypes.Production]: {
      splitByProduct: boolean;
      showDeviations: boolean;
      showReferences: Record<string, boolean>;
    };
    [ChartTypes.DeviationAndDeferments]: {
      splitByStatusCount: boolean;
      showDeviations: boolean;
      defermentTypes: DefermentType[];
    };
    [ChartTypes.ProductionEfficency]: {
      trendsVisibility: Record<string, TrendTypeVisibility>;
    };
  };
};

export type Preferences = {
  /**
   * Last selected root asset selected by the user (externalId).
   * This is also used to assess users' first access in the app. If it's null,
   * then it's the first access.
   */
  lastSelectedRootAsset: string;

  /**
   * Preferred language.
   * Defaults to English.
   */
  language: string;

  /**
   * KPI dashboard preferences
   */
  kpi: KPIPreferences;

  /**
   * Well Summary preferences
   */
  wellSummary: {
    sort?: SortState;
    filter: WellSummaryFilter;
  };

  /**
   * Deep Dive preferences
   */
  deepDive: {
    selectedProduct: string;
    charts: {
      [ChartTypes.Production]: {
        splitByProduct: boolean;
        showDeviations: boolean;
        showDeferments: boolean;
        showReferences: Record<string, boolean>;
        visibilityByProduct: VisibilityByProduct;
      };
      [ChartTypes.ProductionTrends]: {
        visibilityByProduct: VisibilityByProduct;
        merged: boolean;
      };
      [ChartTypes.Sensors]: {
        visibilityByProduct: VisibilityByProduct;
      };
    };
  };

  /**
   * Last selected predefined timerange
   */
  predefinedTimerange: number;

  /**
   * Preferred units per product category.
   * Units can vary depending on the root asset.
   */
  units: {
    [rootAssetExternalId: string]: {
      liquids: string;
      gas: string;
    };
  };

  /**
   * Preferred rates
   */
  rate: RatePreferences;

  /**
   * Layout Preferences
   */
  layout: {
    showAssetNavigation: boolean;
  };

  /**
   * Side Bar Preferences
   */
  sideBar: {
    sortDropdown?: SortState;
    filter: SidebarFilter;
  };

  /**
   * Chart axis/zoom
   */
  chartAxis: Record<string, ChartPreferences>;

  /**
   * Version of zoom pro-tip.
   * Bump it whenever need to display a new zoom pro-tip.
   */
  zoomProTipVersion: number;
};

export const DEFAULT_KPI_REFERENCE = 'bestDay';

export const DEFAULT_PREFERENCES: Preferences = {
  lastSelectedRootAsset: '',
  language: 'en',
  units: {
    default: {
      liquids: 'BOE',
      gas: 'BOE',
    },
  },
  rate: 'Daily',
  kpi: {
    aggregation: 'average',
    selectedReference: {
      system: DEFAULT_KPI_REFERENCE,
      well: DEFAULT_KPI_REFERENCE,
    },
    defermentType: 'actual',
    breakdownByProduct: {},
    selectedProduct: 'HYDROCARBON',
    charts: {
      production: {
        splitByProduct: false,
        showDeviations: true,
        showReferences: {
          bestDay: true,
        },
      },
      deviationAndDeferment: {
        splitByStatusCount: false,
        showDeviations: true,
        defermentTypes: ['actual'],
      },
      productionEfficency: {
        trendsVisibility: {},
      },
    },
  },
  wellSummary: { filter: wellSummaryFilterInitialState },
  deepDive: {
    selectedProduct: 'HYDROCARBON',
    charts: {
      production: {
        splitByProduct: false,
        showDeviations: true,
        showDeferments: true,
        showReferences: {
          bestDay: true,
        },
        visibilityByProduct: {},
      },
      productionTrends: {
        merged: true,
        visibilityByProduct: {},
      },
      sensors: {
        visibilityByProduct: {},
      },
    },
  },
  predefinedTimerange: 30,
  layout: {
    showAssetNavigation: true,
  },
  sideBar: {
    filter: sideBarFilterInitialState,
  },
  chartAxis: {},
  zoomProTipVersion: 0,
};
