import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { Title, Checkbox, Body, Flex, Detail } from '@cognite/cogs.js';

import styled from 'styled-components/macro';
import useNavigation from 'utils/useNavigation';
import { useCollections } from 'features/collections';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useFilter, WellSummaryFilter } from 'features/preferences/filter';
import useDeviationFilterChips from 'features/deviationFilterChips/hooks';
import { mapWellTypeToWellTypeTitle } from 'utils/models/wellListItems';
import {
  ExportWellSummaryDataOptions,
  FormatWellSummarySpec,
} from '../ExportModal';
import { CheckboxList, Container, Section } from './elements';

export type Props = {
  products: {
    type: string;
    capacities: { type: string; isReference: boolean }[];
  }[];
  onChange: (spec: FormatWellSummarySpec) => void;
};

const WellSummaryContents = ({ onChange, products }: Props) => {
  const { t } = useTranslation('ExportModalContents');
  const { isCollectionPage } = useNavigation();
  const { activeCollection } = useCollections();
  const { selectedAsset } = useCurrentAsset();

  const { filter, setDeviationsFilter } =
    useFilter<WellSummaryFilter>('wellSummary');

  const {
    wells: { type: wellTypeFilters },
    deviations: deviationsFilters,
  } = filter;

  const { getChipsData } = useDeviationFilterChips({
    setDeviationsFilter,
  });

  const deviationsFilterChipsData = getChipsData(deviationsFilters);

  const referenceProduct = useMemo(() => {
    return products.find((it) => it.capacities.some((it) => it.isReference))
      ?.type;
  }, [products]);

  const [exportData, setExportData] = useState<ExportWellSummaryDataOptions>(
    () => {
      return Object.assign(
        {
          well: { export: true },
          wellType: { export: true },
        },
        ...products
          .filter((product) => product.type === referenceProduct)
          .map((product) => {
            const referenceCapacity = product.capacities
              .filter((capacity) => capacity.isReference)
              .map((capacity) => {
                return {
                  [`${product.type}_${capacity.type}`]: {
                    export: true,
                    product: product.type,
                    type: capacity.type,
                  },
                };
              });
            return Object.assign(
              {
                [`${product.type}_production`]: {
                  export: true,
                  product: product.type,
                  type: 'production',
                },
              },
              ...referenceCapacity
            );
          }),
        {
          productionEfficiency: { export: true },
        },
        ...products
          .filter((product) => product.type === referenceProduct)
          .map((product) => {
            const capacities = product.capacities
              .filter((capacity) => !capacity.isReference)
              .map((capacity) => {
                return {
                  [`${product.type}_${capacity.type}`]: {
                    export: true,
                    product: product.type,
                    type: capacity.type,
                  },
                };
              });
            return Object.assign({}, ...capacities);
          }),
        {
          deviations: { export: true },
        },
        ...products
          .filter((product) => product.type !== referenceProduct)
          .map((product) => {
            const capacities = product.capacities
              .filter((capacity) => !capacity.isReference)
              .map((capacity) => {
                return {
                  [`${product.type}_${capacity.type}`]: {
                    export: true,
                    product: product.type,
                    type: capacity.type,
                  },
                };
              });
            return Object.assign(
              {
                [`${product.type}_production`]: {
                  export: true,
                  product: product.type,
                  type: 'production',
                },
              },
              ...capacities
            );
          })
      );
    }
  );

  const onDataSelected =
    (field: keyof ExportWellSummaryDataOptions) => (ticked: boolean) => {
      setExportData((prev) => ({
        ...prev,
        [field]: Object.assign(prev[field], { export: ticked }),
      }));
    };

  const onCheckAll = (ticked: boolean) => {
    setExportData(
      Object.keys(exportData).reduce((acc, key) => {
        return {
          ...acc,
          [key]: Object.assign(exportData[key], { export: ticked }),
        };
      }, {} as ExportWellSummaryDataOptions)
    );
  };

  useEffect(() => {
    const updatedSpec: FormatWellSummarySpec = {
      data: {
        ...exportData,
      },
    };
    onChange(updatedSpec);
  }, [exportData, onChange]);

  const allChecked = useMemo(() => {
    const checkValues = (object: ExportWellSummaryDataOptions): boolean[] => {
      return Object.values(object)
        .map((value) => {
          return value.export === true;
        })
        .flat();
    };
    return checkValues(exportData).every((it: boolean) => it === true);
  }, [exportData]);

  return (
    <Container>
      <Section>
        {isCollectionPage && activeCollection ? (
          <>
            <Title level={6}>
              <Trans t={t} i18nKey="collection_header">
                Collection
              </Trans>
            </Title>
            <Body style={{ marginTop: '8px' }} level={2}>
              {activeCollection.name}
            </Body>
          </>
        ) : (
          <>
            <Title level={6}>
              <Trans t={t} i18nKey="system_header">
                System
              </Trans>
            </Title>
            <Body style={{ marginTop: '8px' }} level={2}>
              {selectedAsset?.name}
            </Body>
          </>
        )}
      </Section>
      {(!!wellTypeFilters?.length || !!deviationsFilterChipsData?.length) && (
        <Section>
          <Title level={6}>
            <Trans t={t} i18nKey="filters_header">
              Filters applied
            </Trans>
          </Title>
          <FilterContainer>
            {wellTypeFilters.map((it) => (
              <Chip>
                <Detail
                  style={{
                    color: 'var(--cogs-text-icon--status-neutral)',
                    padding: '4px',
                  }}
                >
                  {mapWellTypeToWellTypeTitle(it)}
                </Detail>
              </Chip>
            ))}
            {deviationsFilterChipsData.map((it) => (
              <Chip>
                <Detail
                  style={{
                    color: 'var(--cogs-text-icon--status-neutral)',
                    padding: '4px',
                  }}
                >
                  {it.title}
                </Detail>
              </Chip>
            ))}
          </FilterContainer>
        </Section>
      )}
      <Section>
        <Title level={6}>
          <Trans t={t} i18nKey="dataChoice_header">
            Data to export
          </Trans>
        </Title>
        <CheckboxList>
          <StyledLi>
            <Checkbox name="all" checked={!!allChecked} onChange={onCheckAll}>
              <Trans t={t} i18nKey="checkall_label">
                Select all
              </Trans>
            </Checkbox>
          </StyledLi>
          {Object.entries(exportData).map(([key, value]) => {
            return (
              <StyledLi>
                <Checkbox
                  name={key}
                  checked={!!value.export}
                  onChange={onDataSelected(key)}
                >
                  <Trans t={t} i18nKey={`${key}_label`}>
                    {key}
                  </Trans>
                </Checkbox>
              </StyledLi>
            );
          })}
        </CheckboxList>
      </Section>
    </Container>
  );
};

const StyledLi = styled.li`
  margin-bottom: 6px;
`;

const Chip = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  width: fit-content;
  align-items: center;
  border-radius: 4px;
  text-transform: capitalize;
  color: var(--cogs-midblue-1);
  background-color: rgba(110, 133, 252, 0.12);
`;

const FilterContainer = styled(Flex)`
  align-items: center;
  grid-gap: 8px;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-bottom: 4px;
`;

export default withI18nSuspense(WellSummaryContents);
