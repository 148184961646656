/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Comment } from 'utils/models/comments';
import groupBy from 'lodash/groupBy';

const commentsSlice = createSlice({
  name: 'comments',
  initialState: {} as CommentsState,
  reducers: {
    create: (
      state,
      action: PayloadAction<{
        parentExternalId: string;
        newComment: Comment;
      }>
    ) => {
      const { parentExternalId, newComment } = action.payload;

      if (!state[parentExternalId]) {
        state[parentExternalId] = [];
      }

      state[parentExternalId].unshift(newComment);
    },
    edit: (
      state,
      action: PayloadAction<{
        parentExternalId: string;
        editedComment: Comment;
      }>
    ) => {
      const { parentExternalId, editedComment } = action.payload;

      state[parentExternalId] = (state[parentExternalId] || []).map(
        (comment) => {
          if (comment.externalId === editedComment.externalId) {
            return editedComment;
          }

          return comment;
        }
      );
    },
    merge: (
      state,
      action: PayloadAction<{
        comments: Comment[];
      }>
    ) => {
      const { comments } = action.payload;

      const commentsBySourceExternalId = groupBy<Comment>(
        [...comments],
        (o) => o.sourceExternalId
      );

      Object.keys(commentsBySourceExternalId).forEach((key) => {
        const storedComments = (state[key] || []) as Comment[];
        const loadedComments = (commentsBySourceExternalId[key] ||
          []) as Comment[];

        // Group loaded and stored comments by external id
        const groupedComments = groupBy<Comment>(
          [...loadedComments, ...storedComments],
          (o) => o.externalId
        );

        // Merge comments by comparing stored and loaded comments and then
        // select the most up to date one
        const mergedComments = Object.keys(groupedComments)
          .map<Comment>((externalId) => {
            const group = groupedComments[externalId];
            // If there is no stored comment, return the loaded comment
            if (group.length === 1) {
              return group[0];
            }

            const loadedComment = group[0];
            const storedComment = group[1];

            // Check which one is the most up to date
            if (
              (loadedComment.editedDate as Date) >
              (storedComment.editedDate as Date)
            ) {
              return loadedComment;
            }

            return storedComment;
          })
          .sort((a, b) => +b.createdDate - +a.createdDate);
        state[key] = mergedComments;
      });
    },
  },
});

export type CommentsState = {
  [parentExternalId: string]: Comment[];
};

export default commentsSlice;
