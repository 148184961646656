import {
  DefermentsFilter,
  DeviationsFilter,
  SidebarFilter,
  WellsFilter,
  WellSummaryFilter,
} from '.';

export const sideBarFilterInitialState: SidebarFilter = {
  deviations: {
    status: [],
    volume: [],
    duration: [],
    isOngoing: false,
    type: [],
  },
  deferments: {
    status: [],
    type: [],
    duration: [],
    choke: [],
    volume: [],
    isOngoing: false,
  },
};

export const wellSummaryFilterInitialState: WellSummaryFilter = {
  wells: { type: [] },
  deviations: {
    status: [],
    volume: [],
    duration: [],
    isOngoing: false,
    type: [],
  },
};

export const isFilterActive = (
  filter: DeviationsFilter | DefermentsFilter | WellsFilter
) => {
  const filtersCount = Object.values(filter).filter((filter) => {
    if (Array.isArray(filter)) {
      return !!filter.length;
    }

    return filter;
  }).length;

  return !!filtersCount;
};

export const isDeviationsFiltered = (deviations: DeviationsFilter) =>
  deviations?.status.length > 0 ||
  deviations?.type.length > 0 ||
  deviations?.duration.length > 0 ||
  deviations?.volume.length > 0 ||
  deviations?.isOngoing;

const isDeferementsFiltered = (deferments: DefermentsFilter) =>
  deferments?.status.length > 0 ||
  deferments?.type.length > 0 ||
  deferments?.duration.length > 0 ||
  deferments?.choke.length > 0;

export const checkIfWellSummaryFiltered = (filter: WellSummaryFilter) => {
  const { deviations } = filter;
  return isDeviationsFiltered(deviations) || filter.wells?.type.length > 0;
};
export const checkIfSidepanelFiltered = (filter: SidebarFilter) => {
  const { deviations, deferments } = filter;

  return isDeviationsFiltered(deviations) || isDeferementsFiltered(deferments);
};
