// TODO(BEST-460): Make this configurable
export const mapWellTypeToWellTypeTitle = (type: string) => {
  let wellTypeTitle;
  // TODO(BEST-1519): Remove hardcoding
  switch (type) {
    case 'OIL':
      wellTypeTitle = 'Producer Oil';
      break;
    case 'GAS':
      wellTypeTitle = 'Producer Gas';
      break;
    case 'NONASSOC_GAS':
      wellTypeTitle = 'Producer Natural Gas';
      break;
    case 'ASSOC_GAS':
      wellTypeTitle = 'Producer Associated Gas';
      break;
    case 'WATER_INJECTION':
    case 'H2O_INJ':
      wellTypeTitle = 'Injector';
      break;
    default:
      wellTypeTitle = 'Unknown type';
  }
  return wellTypeTitle;
};
