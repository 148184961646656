import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import config from 'utils/config';
import { Metrics } from '@cognite/metrics';
import { configureI18n } from '@cognite/react-i18n';
import App from 'app';

import '@cognite/cogs.js/dist/cogs.css';
import 'antd/dist/antd.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { SIDECAR } from 'utils/sidecar';
import { getTenant } from 'utils/tenancy';
import * as serviceWorker from './serviceWorker';

import { version } from '../package.json';

const { applicationId } = SIDECAR;

if (process.env.REACT_APP_RELEASE_ID_HASH) {
  Sentry.init({
    dsn: 'https://84187f6122244e0abad59969dcc06324@o124058.ingest.sentry.io/5214648',
    release: `bestday-demo-${version}-${process.env.REACT_APP_RELEASE_ID_HASH}`,
    environment: config.env,
    ignoreErrors: ['ResizeObserver loop'],
    denyUrls: ['cognite-sre'],
    initialScope: {
      tags: {
        project: getTenant(),
      },
    },
    beforeSend: (event) => {
      if (config.env !== 'production') {
        return null;
      }
      return event;
    },
  });
}

if (process.env.REACT_APP_MIXPANEL_TOKEN) {
  Metrics.init({
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN,
    debug: process.env.REACT_APP_MIXPANEL_DEBUG === 'true',
    environment: config.env,
    project: getTenant() || 'unknown-tenant',
    applicationId,
  });
}

configureI18n({
  locize: {
    projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || '',
    apiKey: process.env.REACT_APP_LOCIZE_API_KEY || '',
  },
  saveMissing: true,
  // We don't want to update translations with new default values
  // since the texts in our code are not always the source of truth, but instead
  // we usually update translations on Locize only.
  updateMissing: false,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
