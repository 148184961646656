import dayjs from 'dayjs';

export const formatDateAs = {
  datetime: (d: Date | number | string) => dayjs(d).format('DD.MM.YY HH:mm'),
  timedate: (d: Date | number | string) => dayjs(d).format('HH:mm DD.MM.YY'),
  timedaymonth: (d: Date | number | string) => dayjs(d).format('HH:mm DD.MM'),
  date: (d: Date | number | string) => dayjs(d).format('DD.MM.YY'),
  time: (d: Date | number | string) => dayjs(d).format('HH:mm'),
  dateWithMonthName: (d: Date | number | string) =>
    dayjs(d).format('MMM D, YYYY'),
  monthNameDate: (d: Date | number | string) => dayjs(d).format('MMM D'),
  humanizedTime: (d: Date | number | string) => dayjs(d).fromNow(),
} as const;
