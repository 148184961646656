interface SortableProduct {
  position?: number;
  product?: string;
}

export const sortByPositionAndProduct = (
  itemA: SortableProduct,
  itemB: SortableProduct
) => {
  if (itemA.position && itemB.position) {
    return itemA.position - itemB.position;
  }

  if (!itemA.position && !itemB.position && itemA.product && itemB.product) {
    return itemA.product?.localeCompare(itemB.product);
  }

  if (!itemA.position) {
    return 1;
  }
  return -1;
};
