import { combineReducers } from '@reduxjs/toolkit';
import advisors from 'features/advisors';
import assetTree from 'features/assetTree/reducer';
import auth from 'features/auth';
import chart from 'features/chart';
import comments from 'features/comments';
import collections from 'features/collections';
import datasets from 'features/datasets';
import deferments from 'features/deferments';
import deviationGroups from 'features/deviationGroups';
import deviations from 'features/deviations';
import facilities from 'features/facilities';
import fullscreen from 'features/fullscreen';
import hoveredDeviation from 'features/deviations/hoveredDeviationReducer';
import intradayChart from 'features/intradayChart';
import preferences from 'features/preferences';
import selectedCharts from 'features/selectedCharts';
import timeRange from 'features/timeRange';
import unitConversion from 'features/unitConversion';
import defermentFieldsSlice from 'features/defermentMatrix';
import groupEventTab from 'features/groupEventTab/';

export default combineReducers({
  assetTree: assetTree.reducer,
  advisors: advisors.reducer,
  auth: auth.reducer,
  chart: chart.reducer,
  comments: comments.reducer,
  collections: collections.reducer,
  datasets: datasets.reducer,
  deferments: deferments.reducer,
  defermentFields: defermentFieldsSlice.reducer,
  deviationGroups: deviationGroups.reducer,
  deviations: deviations.reducer,
  facilities: facilities.reducer,
  fullscreen: fullscreen.reducer,
  hoveredDeviation: hoveredDeviation.reducer,
  intradayChart: intradayChart.reducer,
  preferences: preferences.reducer,
  selectedCharts: selectedCharts.reducer,
  timeRange: timeRange.reducer,
  unitConversion: unitConversion.reducer,
  groupEventTab: groupEventTab.reducer,
});
