import React from 'react';
import { Dropdown, Icon, Menu } from '@cognite/cogs.js';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import isEmpty from 'lodash/isEmpty';
import { usePreferences } from 'features/preferences';
import RootAssetList from 'components/RootAssetList/RootAssetList';
import { useRootAssetHierarchy } from 'components/RootAssetList/useRootAssetHierarchy';
import { StyledAssetSelectorWrapper } from './elements';

const AssetSelector = () => {
  const { rootAsset, setRootAsset, setSelectedAsset } = useCurrentAsset();
  const { setPreferences } = usePreferences();
  const rootAssets = useRootAssetHierarchy();

  if (rootAssets.length === 1 && isEmpty(rootAssets[0].children)) {
    return <span>{rootAsset?.name}</span>;
  }

  return (
    <Dropdown
      key={rootAsset?.externalId}
      content={
        <Menu>
          <RootAssetList
            onSelect={(asset) => {
              setPreferences({ lastSelectedRootAsset: asset.externalId });
              setRootAsset(asset);
              setSelectedAsset(asset);
            }}
          />
        </Menu>
      }
    >
      <StyledAssetSelectorWrapper>
        {rootAsset?.name}
        <Icon type="ChevronDown" />
      </StyledAssetSelectorWrapper>
    </Dropdown>
  );
};

export default AssetSelector;
