import React, { useCallback } from 'react';
import { Body, Avatar, Tooltip, Menu } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import { getEndOfDay, useDateFormats } from 'utils/datetime';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';

import Flex from 'components/Layout/Flex';
import { Notification } from 'utils/models/notifications/api';
import { Link } from 'react-router-dom';
import { Pages } from 'utils/models/enums';
import { useGetComments } from 'features/comments';
import { useSelectedDeviation } from 'features/deviations';
import { useTimeRange } from 'features/timeRange';
import dayjs from 'dayjs';
import { useGroupEventTab } from 'features/groupEventTab';
import { AcknowledgeButton } from './AcknowledgeButton';
import { useNotifications } from '../hook';

export type NotificationMenuItemProps = {
  deleting?: boolean;
  onClose: () => void;
} & Notification;

const NotificationMenuItem = ({
  notificationId,
  assetExternalId,
  eventExternalId: deviationExternalId,
  userName,
  createdTime,
  message,
  assetName,
  deviationDate,
  deleting,
  onClose,
}: NotificationMenuItemProps) => {
  const { datetime, humanizedTime, monthWithDate } = useDateFormats();
  const { t } = useTranslation('NotificationMenu');

  const { deleteNotification } = useNotifications();
  const { setSelectedDeviationExternalId } = useSelectedDeviation();
  const { setSelectedTab } = useGroupEventTab(deviationExternalId);

  const { start, end, setTimeRange } = useTimeRange();
  const adjustTimeRange = useCallback(
    (deviationDate: Date) => {
      if (+deviationDate < start || end < +deviationDate) {
        const newStart = +dayjs(deviationDate).subtract(3, 'day');
        const newEnd = +getEndOfDay(+dayjs(deviationDate).add(3, 'day'));

        setTimeRange(newStart, newEnd);
      }
    },
    [end, setTimeRange, start]
  );

  const { invalidate } = useGetComments({
    externalIds: [deviationExternalId],
    resourceType: 'event',
  });

  const gotoComment = () => {
    setSelectedTab('activity');
    invalidate();
    adjustTimeRange(deviationDate);
    setSelectedDeviationExternalId(deviationExternalId);
    onClose();
  };

  return (
    <UnstyledLink
      key={notificationId}
      to={`/assets/${encodeURIComponent(assetExternalId)}/${Pages.DeepDive}`}
      style={{
        width: '100%',
        display: 'inline-block',
      }}
      onClick={gotoComment}
    >
      <StyledMenuItem>
        <Container>
          <TopSection>
            <Flex.Row gap={8}>
              <Avatar
                square
                size={40}
                text={userName}
                style={{ userSelect: 'none', minWidth: '24px' }}
              />
              <Flex.Column gap={0}>
                <StrongBody2>{userName}</StrongBody2>
                <Flex.Row gap={0}>
                  <StrongBody2>{assetName},&nbsp;</StrongBody2>
                  <SecondaryText>
                    <Trans t={t} i18nKey="notification-menu_deviation">
                      Deviation:&nbsp;
                    </Trans>
                  </SecondaryText>
                  <StrongBody2>{monthWithDate(deviationDate)}</StrongBody2>
                </Flex.Row>
              </Flex.Column>
            </Flex.Row>
            <CommentMessage>{message}</CommentMessage>
          </TopSection>

          <FooterSection>
            <Tooltip content={datetime(createdTime)} placement="bottom">
              <SecondaryText>{humanizedTime(createdTime)}</SecondaryText>
            </Tooltip>
            <AcknowledgeButton
              acknowledged={!!deleting}
              onAcknowledge={() => deleteNotification(notificationId)}
            />
          </FooterSection>
        </Container>
      </StyledMenuItem>
    </UnstyledLink>
  );
};

const StyledMenuItem = styled(Menu.Item)`
  padding: 0px;
  margin: 0px 0px 4px 0px;
  overflow: visible;
  background: var(--cogs-white);
`;

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

const Container = styled.div`
  flex: 1;
  box-shadow: 0px 1px 8px rgba(79, 82, 104, 0.1),
    0px 1px 1px rgba(79, 82, 104, 0.1);
  border-radius: var(--cogs-border-radius--small);
  text-align: left;

  &:hover {
    background-color: var(--cogs-midblue-8);
  }
`;

const TopSection = styled.div`
  .cogs-avatar {
    font-size: 10px;
  }

  padding: 16px 16px 8px 16px;
`;

const FooterSection = styled(Flex.Row)`
  border-top: 1px solid #e8e8e8;
  justify-content: space-between;
  padding-left: 16px;
  align-items: center;
`;

const StrongBody2 = styled(Body).attrs({ level: 2, strong: true })`
  text-overflow: ellipsis;
  overflow: hidden;
`;
const SecondaryText = styled(Body).attrs({ level: 2 })`
  color: var(--cogs-greyscale-grey6);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CommentMessage = styled(Body).attrs({ level: 2 })`
  margin-top: 4px;
  text-align: start;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default withI18nSuspense(NotificationMenuItem);
