import { FormatWellSummarySpec } from 'components/ExportModal/ExportModal';
import { WellListItem } from './model';
import { ValueWithUnit } from './types';
import { mapWellTypeToWellTypeTitle } from './utils';

export const createWellSummaryExport = (
  wellListItems: WellListItem[],
  exportWellSummarySpec?: FormatWellSummarySpec,
  _referenceType?: string
) => {
  const getProductData = () => {
    return Object.values(exportWellSummarySpec?.data || {}).filter(
      (value) => !!value.product
    );
  };

  return wellListItems.map((it) => {
    return Object.assign(
      {
        well: exportWellSummarySpec?.data.well?.export ? it.name : undefined,
        wellType: exportWellSummarySpec?.data.wellType?.export
          ? mapWellTypeToWellTypeTitle(it.mainProduct)
          : undefined,
      },
      ...getProductData().reduce((acc, value) => {
        if (!value.export) {
          return acc;
        }
        if (value.type === 'production' && it.type === value.product) {
          const production = it.productionAverage?.value
            ? {
                [`${value.product}_production`]: it.productionAverage,
              }
            : undefined;

          return [...acc, { ...(production || {}) }];
        }
        if (it.type === value.product) {
          const capacity = it.capacities.find((it) => it.type === value.type);
          const capacityData = capacity
            ? {
                [`${value.product}_${value.type}`]: {
                  value: capacity?.value || 0,
                  unit: capacity?.unit || '',
                } as ValueWithUnit,
              }
            : undefined;
          return [...acc, { ...(capacityData || {}) }];
        }
        return acc;
      }, [] as { [key: string]: ValueWithUnit }[]),
      {
        productionEfficiency:
          exportWellSummarySpec?.data.productionEfficiency?.export &&
          it.productionEff?.value > 0
            ? { value: it.productionEff.value, unit: '%' }
            : undefined,
      },
      {
        deviations:
          exportWellSummarySpec?.data.deviations?.export &&
          it.deviations?.length
            ? it.deviations.reduce(
                (acc, item) => {
                  return {
                    value: item.volume + acc.value,
                    unit: item.unit ? item.unit : acc.unit,
                  };
                },
                { value: 0, unit: '' } as ValueWithUnit
              )
            : undefined,
      }
    );
  });
};
