import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Title, Flex, LabelVariants } from '@cognite/cogs.js';
import { ReactComponent as CommentIcon } from 'assets/CommentIcon.svg';
import {
  Deferment,
  DefermentStatus,
  DefermentType,
  Deviation,
  DeviationStatus,
} from 'utils/models';

import { DeviationGroup } from 'utils/models/deviationGroupes';
import EventStatuses from './DesignElements/EventStatuses';
import DeviationCardIndicator from '../DeviationsPanel/DeviationPanelCard/DeviationCardIndicator';
import CardHeader from './DesignElements/CardHeader';
import ProductList from './DesignElements/ProductList';

export type StatusPart = { status: DeviationStatus; percentage: number };

type DefermentDetails = {
  type: 'Deferment';
  status: DefermentStatus;
  statusLabelVariant: LabelVariants;
  defermentType: DefermentType;
  isBestDayDeferment?: boolean;
};
type DeviationDetails = {
  type: 'Deviation';
  status: DeviationStatus;
  statusLabelVariant: LabelVariants;
  isHovered: boolean;
  loadingComments: boolean;
  commentsCount: number;
  updateStatus: (newStatus: DeviationStatus) => void;
  setHoveredDeviation: (deviation?: Deviation | DeviationGroup) => void;
  statusParts?: StatusPart[];
};

export type Props = {
  panelEvent: Deviation | DeviationGroup | Deferment;
  startDate: Date;
  endDate?: Date;
  title: string | React.ReactNode;
  products: {
    product: string;
    value: number;
    unit: string;
  }[];
  details: DefermentDetails | DeviationDetails;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  color: string;
  stripes?: boolean;
  showOnlyStart?: boolean;
  productListTotal?: number;
  withHighlight?: boolean;
};

const PanelCard = ({
  panelEvent,
  startDate,
  endDate,
  title,
  products,
  details,
  onClick,
  color,
  stripes,
  showOnlyStart,
  productListTotal,
  withHighlight,
}: Props) => {
  const renderDeviationFooter = (details: DeviationDetails) => (
    <>
      <EventStatuses
        status={details.status}
        statusParts={details.statusParts}
      />
      <DeviationCardIndicator
        loading={details.loadingComments}
        count={details.commentsCount}
        icon={<CommentIcon className="cogs-icon" />}
      />
    </>
  );
  const renderDefermentFooter = (details: DefermentDetails) => {
    const { isBestDayDeferment } = details;

    return (
      <DefermentStatuses>
        <EventStatuses
          status={details.status}
          defermentType={details.defermentType}
          isBestDayDeferment={isBestDayDeferment}
        />
      </DefermentStatuses>
    );
  };

  return (
    <Card
      stripes={stripes}
      color={color}
      onClick={onClick}
      onMouseEnter={() => {
        if (details.type === 'Deviation') {
          (details as DeviationDetails).setHoveredDeviation(
            panelEvent as Deviation | DeviationGroup
          );
        }
      }}
      onMouseLeave={() => {
        if (details.type === 'Deviation') {
          (details as DeviationDetails).setHoveredDeviation(undefined);
        }
      }}
      hovered={details.type === 'Deviation' && details.isHovered}
      withHighlight={withHighlight}
    >
      <CardHeader
        startDate={startDate}
        endDate={endDate}
        lastUpdatedTime={panelEvent.lastUpdatedTime}
        showOnlyStart={showOnlyStart}
      />
      <CardBody>
        {title && <CardTitle level={5}>{title}</CardTitle>}
        <ProductList productList={products} total={productListTotal} />
      </CardBody>
      <CardFooter>
        {details.type === 'Deferment'
          ? renderDefermentFooter(details)
          : renderDeviationFooter(details)}
      </CardFooter>
    </Card>
  );
};

export const Card = styled.div<{
  hovered?: boolean;
  stripes?: boolean;
  withHighlight?: boolean;
}>`
  width: 100%;
  padding: 12px;
  margin-bottom: 16px;
  background: var(--cogs-white);
  border-radius: 12px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.08),
    0px 1px 4px rgba(0, 0, 0, 0.06);
  cursor: pointer;

  ${({ withHighlight = false, hovered }) => {
    if (!withHighlight) {
      return css`
        :hover {
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.03),
              rgba(0, 0, 0, 0.03)
            ),
            var(--cogs-white);
        }
        ${hovered &&
        `
          background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.03),
              rgba(0, 0, 0, 0.03)
            ),
            var(--cogs-white);
        `}
      `;
    }

    return (
      !hovered &&
      css`
        opacity: 0.4;
        transition: opacity 0.15s;
        transition-timing-function: ease-out;
      `
    );
  }}
`;

export const CardTitle = styled(Title)`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const CardBody = styled.div`
  margin-bottom: 8px;
`;

const CardFooter = styled(Flex)`
  justify-content: space-between;
  span {
    cursor: pointer;
  }
`;

const DefermentStatuses = styled(Flex)`
  span {
    margin-right: 4px;
  }
`;

export default PanelCard;
