import { UnitPreferences } from 'features/preferences';
import { ProductConversions } from 'features/unitConversion';
import { FormatSpec } from 'components/ExportModal/ExportModal';
import { getClient } from 'utils/cognitesdk';
import { SIDECAR } from 'utils/sidecar';
import { TemplatesMapping } from 'hooks/types';
import { BestDayAsset } from 'containers/CurrentAssetProvider';

type ExportOption = {
  asset: BestDayAsset;
  spec: FormatSpec;
  fileTitle: string;
  productConversions: ProductConversions;
  units: UnitPreferences;
  templateInfo?: TemplatesMapping;
  project: string;
};
export const exportDataClientSide = async (
  content: string,
  fileName: string,
  mimeType: string = 'text/csv'
) => {
  const a = document.createElement('a');
  if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    );
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    // eslint-disable-next-line no-restricted-globals
    location.href = `data:${mimeType},${encodeURIComponent(content)}`; // only this mime type is supported
  }
};

export const exportData = async ({
  asset,
  spec,
  fileTitle,
  productConversions,
  units,
  templateInfo,
  project,
}: ExportOption) => {
  const { bestdayApiBaseUrl } = SIDECAR;

  const {
    dateRange,
    data: {
      deferments,
      bestDayDeferments,
      deviations,
      comments,
      productionData,
      bestDay,
    },
  } = spec;
  const [start, end] = dateRange.map((m) => +m);

  const result = await getClient().post(`${bestdayApiBaseUrl}/export`, {
    withCredentials: true,
    headers: {
      'x-cdp-project': project,
    },
    data: {
      assetExternalId: asset.externalId!,
      start,
      end,
      production: productionData,
      deferments,
      bestDayDeferments,
      comments,
      deviations,
      bestDay,
      productConversions,
      unitPreferences: units,
      templateInfo: {
        group: templateInfo?.APPLICATION_DATA.group,
        version: templateInfo?.APPLICATION_DATA.version,
        level: asset.networkLevel === 'Well' ? 'well' : 'system',
      },
      matrixTemplateInfo: {
        group: templateInfo?.DEFERMENT_MATRIX.group,
        version: templateInfo?.DEFERMENT_MATRIX.version,
      },
    },
  });

  const payload = result.data;
  const filePath = `data:application/zip;base64,${payload.content}`;
  const a = document.createElement('a');
  a.href = filePath;
  a.download = fileTitle;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
