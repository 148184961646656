import { Asset } from '@cognite/sdk';
import groupBy from 'lodash/groupBy';
import { useEffect, useState } from 'react';
import { useRootAssets, sortAssetsByName } from 'utils/models/systems';

export type AssetWithChidren = {
  asset: Asset;
  children: Asset[];
};

export const useRootAssetHierarchy = (): AssetWithChidren[] => {
  const [rootAssets, setRootAssets] = useState<AssetWithChidren[]>([]);

  const { data: assets, isLoading, error } = useRootAssets();

  useEffect(() => {
    if (isLoading || !assets || error) {
      return;
    }

    const groups = groupBy(assets, ({ parentId }) => {
      // If user has access to asset's parent, then add asset to children list
      // Otherwise, add to the list of countries so it is displayed flatly
      if (assets.some((asset) => asset.id === parentId)) {
        return parentId;
      }

      return 'countries';
    });

    setRootAssets(
      groups.countries
        .map((asset) => ({
          asset,
          children: (groups[asset.id] || []).sort(sortAssetsByName),
        }))
        .sort(({ asset: assetA }, { asset: assetB }) =>
          assetA.name.localeCompare(assetB.name, undefined, {
            numeric: true,
          })
        )
    );
  }, [assets, error, isLoading]);

  return rootAssets;
};
