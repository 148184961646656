import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { EventFilter } from '@cognite/sdk';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useTimeRange } from 'features/timeRange';
import {
  convertToDeviationGroups,
  DeviationSegment,
  fetchDeviationGroups,
  editGroup,
  PropsToEdit,
  convertToBestDayDeferment,
} from 'utils/models/deviationGroupes';
import { useDeviations } from 'features/deviations';
import { useProjectContext } from 'containers/AuthContainer';
import { useCollections } from 'features/collections';
import { sleep } from 'utils/sleep';
import { Deferment } from 'utils/models';
import { useUnitConversion } from 'features/unitConversion';
import { useDefermentFields } from 'features/defermentMatrix';
import { reportException } from '@cognite/react-errors';
import { PerfMetrics } from '@cognite/metrics';
import { METRICS } from 'utils/metrics/enums';
import deviationGroupsSlice, { DeviationGroupsState } from './reducer';

let lastDeviationFetchIdx: number = 0;

export const useDeviationGroups = () => {
  const {
    deviationGroups,
    deviationSegments,
    bestDayDeferments,
    loading,
    error,
    updateLoading,
    updateError,
  } = useSelector<RootState, DeviationGroupsState>((state) => {
    return state.deviationGroups;
  });
  const dispatch = useDispatch();
  const { convertValue } = useUnitConversion();

  const { selectedAsset } = useCurrentAsset();
  const { start, end } = useTimeRange();
  const { getCurrentCollection } = useCollections();
  const currentCollection = getCurrentCollection();

  const { project } = useProjectContext();
  const { convertDeviations } = useDeviations();

  const { fields, ignoredFields } = useDefermentFields();

  const convertBestDayDefermentVolume = useCallback(
    (bdDeferments: Deferment[], preferredUnit?: string) => {
      return bdDeferments.map((bdDeferment) => {
        const convertedVolumes = bdDeferment.volumes.map((volume) => {
          const convertedVolume = convertValue({
            value: volume.value,
            product: volume.product,
            sourceUnit: volume.unit,
            targetUnit: preferredUnit,
            skipRateConversion: true,
          });
          if (convertedVolume) {
            return {
              ...volume,
              value: convertedVolume.value,
              unit: convertedVolume.convertedUnit,
            };
          }
          return volume;
        });
        return {
          ...bdDeferment,
          volumes: convertedVolumes,
        };
      });
    },
    [convertValue]
  );

  const getDeviationGroups = useCallback(
    ({
      externalIds = selectedAsset?.externalId
        ? [selectedAsset.externalId || '']
        : currentCollection?.favorites?.map((fav) => fav.externalId) || [],
      alternativeUnit = '',
      delayedFetching = false,
    }: {
      externalIds?: string[];
      alternativeUnit?: string;
      delayedFetching?: boolean;
    } = {}) => {
      let canceled = false;
      const cancel = () => {
        canceled = true;
      };

      const filter: EventFilter = {
        activeAtTime: {
          min: start,
          max: end,
        },
      };

      if (
        !externalIds ||
        externalIds?.length === 0 ||
        externalIds?.every((e) => !e)
      ) {
        dispatch(
          deviationGroupsSlice.actions.getDeviationGroupsSuccess({
            loading: false,
            deviationGroups: [],
            deviationSegments: [],
            bestDayDeferments: [],
          })
        );
        return cancel;
      }

      filter.assetExternalIds = externalIds;

      dispatch(
        deviationGroupsSlice.actions.getDeviationGroups({
          loading: true,
        })
      );

      sleep(delayedFetching ? 2500 : 0).then(() => {
        lastDeviationFetchIdx += 1;
        const myDeviationFetchIdx = lastDeviationFetchIdx;

        fetchDeviationGroups(filter, project)
          .then((deviationGroupEvents) => {
            if (canceled) {
              return;
            }

            if (myDeviationFetchIdx !== lastDeviationFetchIdx) {
              return;
            }

            const deviationGroups = convertToDeviationGroups(
              deviationGroupEvents,
              fields.concat(ignoredFields)
            );
            const bestDayDeferments =
              convertToBestDayDeferment(deviationGroupEvents);
            const deviationSegments = deviationGroups.reduce(
              (acc: DeviationSegment[], group) => {
                const segmentsWithStatus =
                  group.deviationSegments?.map((segment: DeviationSegment) => ({
                    ...segment,
                    groupStatus: group.status,
                  })) || [];
                return acc.concat(segmentsWithStatus);
              },
              [] as DeviationSegment[]
            );
            const preferredUnitGroupes = convertDeviations(
              deviationGroups,
              alternativeUnit
            );
            const preferredUnitBestDayDeferments =
              convertBestDayDefermentVolume(bestDayDeferments, alternativeUnit);

            dispatch(
              deviationGroupsSlice.actions.getDeviationGroupsSuccess({
                loading: false,
                deviationGroups: preferredUnitGroupes,
                deviationSegments,
                bestDayDeferments: preferredUnitBestDayDeferments,
              })
            );
          })
          .catch((ex) => {
            reportException(ex);
            dispatch(
              deviationGroupsSlice.actions.getDeviationGroupsError({
                loading: false,
                error: ex,
              })
            );
          });
      });

      return cancel;
    },
    [
      convertBestDayDefermentVolume,
      convertDeviations,
      dispatch,
      end,
      currentCollection,
      fields,
      ignoredFields,
      project,
      selectedAsset?.externalId,
      start,
    ]
  );

  const editDeviationGroup = (groupId: string, updateProps: PropsToEdit) => {
    PerfMetrics.trackPerfStart(METRICS.GroupFormUpdate);
    dispatch(
      deviationGroupsSlice.actions.updateEvent({
        updateLoading: true,
      })
    );
    editGroup(groupId, updateProps, project)
      .then((updatedEventArray) => {
        const updatedGroup = convertToDeviationGroups(
          updatedEventArray,
          fields.concat(ignoredFields)
        )[0];
        const baseGroup = deviationGroups.find(
          ({ externalId }) => externalId === updatedGroup.externalId
        );
        dispatch(
          deviationGroupsSlice.actions.updateEventSuccess({
            updatedGroup: {
              ...updatedGroup,
              volume: baseGroup?.volume || 0,
              volumes: baseGroup?.volumes || [],
            },
            updateLoading: false,
          })
        );

        getDeviationGroups({ delayedFetching: true });
        PerfMetrics.trackPerfEnd(METRICS.GroupFormUpdate);
        PerfMetrics.logSuccessEvent(METRICS.GroupFormUpdate);
      })
      .catch((ex) => {
        reportException(ex);
        dispatch(
          deviationGroupsSlice.actions.updateEventError({
            updateLoading: false,
            updateError: ex,
          })
        );
        PerfMetrics.logFailureEvent(METRICS.GroupFormUpdate);
      });
  };

  const resetUpdateError = () => {
    dispatch(
      deviationGroupsSlice.actions.updateEventError({
        updateLoading: false,
        updateError: undefined,
      })
    );
  };

  return {
    deviationGroups,
    deviationSegments,
    bestDayDeferments,
    loading,
    error,
    updateLoading,
    updateError,
    getDeviationGroups,
    editDeviationGroup,
    resetUpdateError,
  };
};
