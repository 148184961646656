import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import styled from 'styled-components/macro';
import { Checkbox, Badge, Colors, Icon } from '@cognite/cogs.js';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';

import { sortByPositionAndProduct } from 'utils/productsRelevance';
import { getProducts, useTemplatesQuery } from 'hooks/useGraphQlQuery';
import useConfig from 'hooks/useConfig';

import { Product } from 'graphql-types';
import { useProductInfo } from 'pages/WellDeepDive/hooks/useProductInfo';
import { CheckboxList } from '../elements';

export type Props = {
  timePeriodEnd?: number;
  onChange: (productionData: string[]) => void;
};

const ProductionData = ({ onChange, timePeriodEnd }: Props) => {
  const { t } = useTranslation('ProductionData');
  const { t: tProducts } = useTranslation('Products');

  const { selectedAsset } = useCurrentAsset();
  const { rootAssetConfig } = useConfig();
  const { info } = useProductInfo();

  const [expanded, setExpanded] = useState(true);

  const [productStates, setProductStates] = useState<{
    [product: string]: boolean;
  }>({});

  const { data: templateProducts, isLoading: isLoadingMainProduct } =
    useTemplatesQuery<Product[]>(
      { fn: getProducts, key: `getProducts-${selectedAsset?.externalId}` },
      {
        externalId: selectedAsset?.externalId,
        level: selectedAsset?.networkLevel === 'Well' ? 'well' : 'system',
        end: timePeriodEnd,
        templateInfo: rootAssetConfig?.templates,
      },
      {
        enabled: !!selectedAsset && !!rootAssetConfig?.templates,
      }
    );

  const allProducts: string[] = useMemo(() => {
    if (templateProducts) {
      return templateProducts
        ?.sort((a, b) => {
          const productA = info(a.type);
          const productB = info(b.type);
          return sortByPositionAndProduct(
            { ...productA, product: a.type },
            { ...productB, product: b.type }
          );
        })
        .map((product) => product.type);
    }
    return [];
  }, [info, templateProducts]);

  useEffect(() => {
    setProductStates(
      allProducts.reduce(
        (acc, product) => ({ ...acc, [product]: true }),
        {} as { [product: string]: boolean }
      )
    );
  }, [allProducts]);

  const isAnyChecked = useCallback(
    () => allProducts?.some((product) => productStates[product]),
    [productStates, allProducts]
  );

  const updateAll = (checked: boolean) => {
    setProductStates((prevState) => {
      return Object.keys(prevState).reduce(
        (acc, key) => ({
          ...acc,
          [key]: checked,
        }),
        {}
      );
    });
  };

  useEffect(() => {
    onChange(allProducts?.filter((product) => productStates[product]));
  }, [onChange, productStates, allProducts]);

  const productCount = useMemo(() => {
    return Object.values(productStates).filter(Boolean).length;
  }, [productStates]);

  if (allProducts.length === 0 || isLoadingMainProduct) {
    return null;
  }

  return (
    <Container>
      <ExpandRow>
        <Checkbox
          style={{ flex: 1 }}
          name="productionData"
          checked={isAnyChecked()}
          onChange={(ticked) => (ticked ? updateAll(true) : updateAll(false))}
        >
          <Trans t={t} i18nKey="productionData_label">
            Production data
          </Trans>
        </Checkbox>
        <ExpandToggle
          tabIndex={0}
          role="button"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setExpanded(!expanded);
            }
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Badge
            text={String(productCount)}
            background={Colors['greyscale-grey2'].hex()}
            style={{ marginRight: 16 }}
          />
          <Icon type={expanded ? 'ChevronDownSmall' : 'ChevronRightSmall'} />
        </ExpandToggle>
      </ExpandRow>
      <ToggleableList visible={expanded}>
        {allProducts.map((product) => {
          return (
            <li key={product}>
              <Checkbox
                name={`${product}-export`}
                checked={!!productStates[product]}
                onChange={(ticked) => {
                  setProductStates((states) => ({
                    ...states,
                    [product]: ticked,
                  }));
                }}
              >
                <Trans t={tProducts} i18nKey={`${product}-product`}>
                  {product}
                </Trans>
              </Checkbox>
            </li>
          );
        })}
      </ToggleableList>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ToggleableList = styled(CheckboxList)<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const ExpandRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExpandToggle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
`;

export default withI18nSuspense(ProductionData);
