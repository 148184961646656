import { useDispatch } from 'react-redux';
import { getSystemTree, useTemplatesQuery } from 'hooks/useGraphQlQuery';
import { TemplatesMapping } from 'hooks/types';
import {
  getLoadedKeysFromDataNodes,
  mapSystemTreeToDataNodes,
  SystemTreeType,
} from 'utils/systemSelector';
import assetTreeSlice from './reducer';

const ASSET_FETCH_DEPTH = 4;

export default function useInitializeAssetTree({
  rootAssetExternalId,
  templateInfo,
}: {
  rootAssetExternalId: string | undefined;
  templateInfo: TemplatesMapping | undefined;
}) {
  const dispatch = useDispatch();

  useTemplatesQuery(
    {
      fn: getSystemTree,
      key: `treeData-${rootAssetExternalId}`,
    },
    {
      externalId: rootAssetExternalId,
      templateInfo,
      depth: ASSET_FETCH_DEPTH,
    },
    {
      enabled: !!rootAssetExternalId && !!templateInfo,
      onSuccess: (data: SystemTreeType[]) => {
        const initialTreeData =
          mapSystemTreeToDataNodes(data[0]).children || [];

        const subLevelsLoadedKeys = getLoadedKeysFromDataNodes(
          initialTreeData,
          ASSET_FETCH_DEPTH - 1
        );

        // Push our initial tree data to the store
        dispatch(
          assetTreeSlice.actions.setTreeData({
            treeData: {
              [rootAssetExternalId as string]: initialTreeData,
            },
            loadedKeys: {
              [rootAssetExternalId as string]: subLevelsLoadedKeys,
            },
          })
        );
      },
    }
  );
}
