import { DataNode } from 'antd/lib/tree';
import { DataNodeWithExId } from 'components/SystemTree/SystemTree';

type Asset = {
  externalId: string;
  id: number;
  name: string;
};

export type SystemTreeType = {
  asset: Asset;
  subSystems: SystemTreeType[];
  wells: { asset: Asset }[];
};

export const mapSystemTreeToDataNodes = (
  data: SystemTreeType
): DataNodeWithExId => {
  return {
    title: data.asset.name,
    key: data.asset.id,
    externalId: data.asset.externalId,
    children: [
      ...(data.subSystems?.map((elem) => mapSystemTreeToDataNodes(elem)) || []),
      ...(data.wells?.map((well) => ({
        title: well.asset.name,
        key: well.asset.id,
        externalId: well.asset.externalId,
        isLeaf: true,
      })) || []),
    ].sort((a, b) =>
      (a.title as string)?.localeCompare(b.title as string, undefined, {
        numeric: true,
      })
    ),
  };
};

export const getLoadedKeysFromDataNodes = (
  data: DataNode[],
  depth: number
): number[] => {
  let keys: number[] = [];

  if (depth === 0) {
    return keys;
  }

  data?.forEach(({ key, children }) => {
    keys.push(key as number);
    if (children && children?.length > 0) {
      keys = [...keys, ...getLoadedKeysFromDataNodes(children, depth - 1)];
    }
  });

  return keys;
};
