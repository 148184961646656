import { useCallback, useMemo } from 'react';
import { useAuth } from 'features/auth';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { EventFilter } from '@cognite/sdk';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useTimeRange } from 'features/timeRange';
import { fetchDeviations, updateDeviation } from 'utils/models/deviations/api';
import {
  DeviationStatus,
  Deviation,
  createDeviations,
} from 'utils/models/deviations';
import { useUnitConversion } from 'features/unitConversion';
import { useDatasets } from 'features/datasets';
import { useCollections } from 'features/collections';
import useNavigation from 'utils/useNavigation';
import { sleep } from 'utils/sleep';
import dayjs from 'dayjs';
import { useTranslation } from '@cognite/react-i18n';
import { DeviationGroup } from 'utils/models/deviationGroupes';
import { reportException } from '@cognite/react-errors';
import { useProducts } from 'pages/WellDeepDive/hooks/useProducts';
import { HYDROCARBON, PRODUCT_TYPE_WATER } from 'utils/products';
import { getStartOfLocalDay } from 'utils/datetime';
import deviationsSlice, { DeviationsState } from './reducer';
import hoveredDeviationSlice, {
  HoveredDeviationState,
} from './hoveredDeviationReducer';

let lastDeviationFetchIdx: number = 0;

export const useDeviations = () => {
  const {
    deviationsLoading,
    deviations,
    convertedDeviations,
    error,
    updateLoading,
    updateError,
  } = useSelector<RootState, DeviationsState>((state) => {
    return state.deviations;
  });
  const dispatch = useDispatch();

  const { getDataset } = useDatasets();
  const { user } = useAuth();

  const { selectedAsset, products } = useCurrentAsset();

  const { filterByAggregatedTo } = useProducts();

  const allProducts = useMemo(() => {
    return filterByAggregatedTo(HYDROCARBON)
      .map(({ type }) => type)
      .concat(PRODUCT_TYPE_WATER);
  }, [filterByAggregatedTo]);

  const { getCurrentCollection } = useCollections();
  const { start, end } = useTimeRange();
  const { isCollectionPage } = useNavigation();

  const { convertValue } = useUnitConversion();

  const convertDeviations = useCallback(
    (deviations: Deviation[], preferredUnit?: string) => {
      return deviations.map((deviation) => {
        const convertedVolumes = deviation.volumes?.map((volume) => {
          const convertedVolume = convertValue({
            value: volume.currentValue,
            product: volume.product,
            sourceUnit: volume.unit,
            targetUnit: preferredUnit,
            skipRateConversion: true,
          });
          let convertedInitialVolume;
          if (volume.initialValue) {
            convertedInitialVolume = convertValue({
              value: volume.initialValue,
              product: volume.product,
              sourceUnit: volume.unit,
              targetUnit: preferredUnit,
              skipRateConversion: true,
            });
          }
          if (convertedVolume) {
            return {
              ...volume,
              currentValue: convertedVolume.value,
              initialValue: convertedInitialVolume?.value,
              unit: convertedVolume.convertedUnit,
            };
          }
          return volume;
        });

        const convertedInitialData = deviation.initialData
          ? {
              deferments: deviation.initialData?.deferments.map((volume) => {
                const convertedVolume = convertValue({
                  value: volume.inputValue,
                  product: volume.product,
                  sourceUnit: volume.unit,
                  targetUnit: preferredUnit,
                  skipRateConversion: true,
                });
                if (convertedVolume) {
                  return {
                    ...volume,
                    inputValue: convertedVolume.value,
                    unit: convertedVolume.convertedUnit,
                  };
                }
                return volume;
              }),
              production: deviation.initialData?.production.map((volume) => {
                const convertedVolume = convertValue({
                  value: volume.inputValue,
                  product: volume.product,
                  sourceUnit: volume.unit,
                  targetUnit: preferredUnit,
                  skipRateConversion: true,
                });
                if (convertedVolume) {
                  return {
                    ...volume,
                    inputValue: convertedVolume.value,
                    unit: convertedVolume.convertedUnit,
                  };
                }
                return volume;
              }),
            }
          : undefined;

        return {
          ...deviation,
          volumes: convertedVolumes,
          initialData: convertedInitialData,
        };
      });
    },
    [convertValue]
  );

  /**
   * @param alternativeUnit if passed, a `convertedDeviations` property will be returned
   */
  const getDeviations = useCallback(
    ({
      externalIds = isCollectionPage
        ? getCurrentCollection()?.favorites.map((fav) => fav.externalId)
        : [selectedAsset?.externalId || ''],
      alternativeUnit = '',
      delayedFetching = false,
    }: {
      externalIds?: string[];
      alternativeUnit?: string;
      delayedFetching?: boolean;
    } = {}) => {
      let canceled = false;
      const cancel = () => {
        canceled = true;
      };

      const filter: EventFilter = {
        activeAtTime: {
          // add 2 hours to the start date so we exclude deviations from the previous day
          // since each deviation has an end date as 12AM of the next day
          min: +dayjs(getStartOfLocalDay(start)).add(
            dayjs.duration({ hours: 2 })
          ),
          max: end,
        },
      };

      if (
        !externalIds ||
        externalIds?.length === 0 ||
        (!start && !end) ||
        externalIds?.every((e) => !e)
      ) {
        dispatch(
          deviationsSlice.actions.getDeviationsSuccess({
            deviationsLoading: false,
            deviations: [],
            convertedDeviations: [],
          })
        );
        return cancel;
      }

      filter.assetExternalIds = externalIds;

      dispatch(
        deviationsSlice.actions.getDeviations({
          deviationsLoading: true,
        })
      );

      sleep(delayedFetching ? 2500 : 0).then(() => {
        lastDeviationFetchIdx += 1;
        const myDeviationFetchIdx = lastDeviationFetchIdx;

        fetchDeviations(filter)
          .then((deviationEvents) => {
            if (canceled) {
              return;
            }

            if (myDeviationFetchIdx !== lastDeviationFetchIdx) {
              return;
            }

            const devs = createDeviations(deviationEvents, allProducts);
            const preferredUnitDeviations = convertDeviations(devs);
            let convertedDevs: Deviation[] = [];
            if (alternativeUnit) {
              convertedDevs = convertDeviations(devs, alternativeUnit);
            }
            dispatch(
              deviationsSlice.actions.getDeviationsSuccess({
                deviationsLoading: false,
                deviations: preferredUnitDeviations,
                convertedDeviations: convertedDevs,
              })
            );
          })
          .catch((ex) => {
            reportException(ex);
            dispatch(
              deviationsSlice.actions.getDeviationsError({
                deviationsLoading: false,
                error: ex,
              })
            );
          });
      });

      return cancel;
    },
    [
      convertDeviations,
      dispatch,
      end,
      getCurrentCollection,
      isCollectionPage,
      allProducts,
      selectedAsset?.externalId,
      start,
    ]
  );
  const getDeviation = (externalId: string) => {
    return deviations.find((d) => {
      return d.externalId === externalId;
    });
  };

  const deviationsByProducts = useMemo(() => {
    return deviations.filter((deviation) =>
      products.includes(deviation.product)
    );
  }, [deviations, products]);

  const updateDeviationStatus = (
    externalId: string,
    newStatus: DeviationStatus
  ) => {
    dispatch(
      deviationsSlice.actions.updateEvent({
        updateLoading: true,
      })
    );
    const baseEvent = getDeviation(externalId);
    if (baseEvent) {
      updateDeviation(
        baseEvent,
        { status: newStatus },
        user,
        getDataset('DEVIATION_UPDATES')
      )
        .then((updatedDeviation) => {
          dispatch(
            deviationsSlice.actions.updateEventSuccess({
              updatedDeviation,
              updateLoading: false,
            })
          );
        })
        .catch((ex) => {
          reportException(ex);
          dispatch(
            deviationsSlice.actions.updateEventError({
              updateLoading: false,
              updateError: ex,
            })
          );
        });
    }
  };

  return {
    deviationsLoading,
    deviations,
    error,
    updateLoading,
    updateError,
    getDeviation,
    getDeviations,
    deviationsByProducts,
    updateDeviationStatus,
    convertDeviations,
    convertedDeviations,
  };
};

export const useIgnoredDeviationOptions = () => {
  const { t } = useTranslation('GroupForm');

  return [
    {
      value: 'incorrectBestDay',
      label: t('incorrect-bd-reason_group-form', {
        defaultValue: 'BestDay seems to be incorrect',
      }),
    },
    {
      value: 'other',
      label: t('other-reason_group-form', {
        defaultValue: 'Other',
      }),
    },
  ];
};

export const useSelectedDeviation = () => {
  const { selectedDeviationExternalId } = useSelector<
    RootState,
    DeviationsState
  >((state) => {
    return state.deviations;
  });
  const dispatch = useDispatch();

  const setSelectedDeviationExternalId = useCallback(
    (externalId?: string) => {
      dispatch(
        deviationsSlice.actions.setSelectedDeviation({
          selectedDeviationExternalId: externalId,
        })
      );
    },
    [dispatch]
  );
  return { selectedDeviationExternalId, setSelectedDeviationExternalId };
};

export const useHoveredDeviation = () => {
  const { hoveredDeviation } = useSelector<RootState, HoveredDeviationState>(
    (state) => {
      return state.hoveredDeviation;
    }
  );

  const dispatch = useDispatch();

  const setHoveredDeviation = useCallback(
    (deviation?: Deviation | DeviationGroup) => {
      if (deviation?.externalId !== hoveredDeviation?.externalId) {
        dispatch(
          hoveredDeviationSlice.actions.setHoveredDeviation({
            hoveredDeviation: deviation,
          })
        );
      }
    },
    [dispatch, hoveredDeviation]
  );

  return {
    hoveredDeviation,
    setHoveredDeviation,
  };
};
