import { createSelector } from 'reselect';
import { RootState } from 'store';
import { CollectionsState } from './reducer';

const selectCollections = (state: RootState): CollectionsState =>
  state.collections;

export const getCollections = createSelector(
  [selectCollections],
  ({ collections }) => collections
);

export const getActiveCollection = createSelector(
  [selectCollections],
  ({ collections, activeCollectionKey }) =>
    collections.find((it) => it.key === activeCollectionKey)
);
