import React from 'react';
import { FlagProvider } from '@cognite/react-feature-flags';
import { SIDECAR } from 'utils/sidecar';
import { getTenant } from 'utils/tenancy';
import config from 'utils/config';

const UnleashProvider: React.FC = (children) => {
  const tenant = getTenant();

  return (
    <FlagProvider
      projectName={tenant}
      appName="bestday"
      apiToken={SIDECAR.unleash}
      // Using the environment as a toggle
      remoteAddress={config.env}
    >
      {children}
    </FlagProvider>
  );
};

export default UnleashProvider;
