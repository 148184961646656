import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DataNode } from 'antd/lib/tree';
import { Key } from 'antd/lib/table/interface';
import { RootState } from 'store';

export type AssetTreeState = {
  treeData: Record<string, DataNode[]>;
  expandedKeys: Key[];
  loadedKeys: Record<string, Key[]>;
};

const assetTreeSlice = createSlice({
  name: 'assetTree',
  initialState: {
    treeData: {},
    expandedKeys: [],
    loadedKeys: {},
  } as AssetTreeState,
  reducers: {
    setTreeData: (
      state,
      action: PayloadAction<{
        treeData: Record<string, DataNode[]>;
        loadedKeys: Record<string, Key[]>;
      }>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.treeData = { ...state.treeData, ...action.payload.treeData };
      // eslint-disable-next-line no-param-reassign
      state.loadedKeys = { ...state.loadedKeys, ...action.payload.loadedKeys };
    },
    setExpandedKeys: (state, action: PayloadAction<Key[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.expandedKeys = action.payload;
    },
  },
});

const selectAssetTree = (state: RootState): AssetTreeState => state.assetTree;

export const selectTreeData = createSelector(
  [selectAssetTree],
  ({ treeData }) => treeData
);

export const selectExpendedKeys = createSelector(
  [selectAssetTree],
  ({ expandedKeys }) => expandedKeys
);

export const selectLoadedKeys = createSelector(
  [selectAssetTree],
  ({ loadedKeys }) => loadedKeys
);

export default assetTreeSlice;
