import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export const useDateFormats = () => {
  // When we're ready for preferences, read the user's date format preferences
  // here and apply them. Timezones can be handled here, too.

  return {
    datetime: (d: Date | number) => dayjs(d).format('DD.MM.YY HH:mm'),
    timedate: (d: Date | number) => dayjs(d).format('HH:mm DD.MM.YY'),
    timedaymonth: (d: Date | number) => dayjs(d).format('HH:mm DD.MM'),
    date: (d: Date | number) => dayjs(d).format('DD.MM.YY'),
    time: (d: Date | number) => dayjs(d).format('HH:mm'),
    humanizedTime: (d: Date | number) => dayjs(d).fromNow(),
    monthWithDate: (d: Date | number) => dayjs(d).format('MMM D, YYYY'),
  } as const;
};
