import { useCallback } from 'react';
import { useAuth } from 'features/auth';
import dayjs from 'dayjs';
import {
  Parent,
  Parents,
  Comment,
  NewComment,
} from 'utils/models/comments/types';
import {
  create,
  edit,
  list,
  NotificationDetails,
} from 'utils/models/comments/api';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useProjectContext } from 'containers/AuthContainer';
import { useQuery, useQueryClient } from 'react-query';
import { PerfMetrics } from '@cognite/metrics';
import { METRICS } from 'utils/metrics/enums';
import commentsSlice, { CommentsState } from './reducer';

export type UseGetComments = {
  loading: boolean;
  loadingError: Error | null;
  invalidate: () => void;
  getComments: (externalId: string) => Comment[];
  getActiveComments: (externalId: string) => Comment[];
};

export type UseComments = {
  createComment: (
    comment: NewComment,
    datasetExternalId: string,
    notificationDetails: NotificationDetails
  ) => Promise<void>;
  editComment: (
    comment: Comment,
    notificationDetails: NotificationDetails
  ) => Promise<void>;
  deleteComment: (
    comment: Comment,
    notificationDetails: NotificationDetails
  ) => Promise<void>;
};

export const useGetComments = ({
  externalIds,
  resourceType,
}: Parents): UseGetComments => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { project } = useProjectContext();

  const commentsState = useSelector<RootState, CommentsState>((state) => {
    return state.comments;
  });

  const getComments = useCallback(
    (externalId: string): Comment[] => {
      return commentsState?.[externalId] || [];
    },
    [commentsState]
  );

  const getActiveComments = useCallback(
    (externalId: string): Comment[] => {
      return (
        commentsState?.[externalId]?.filter(
          (comment) => comment.status !== 'deleted'
        ) || []
      );
    },
    [commentsState]
  );

  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(['fetch-comments', externalIds]);
  }, [externalIds, queryClient]);

  const { isLoading, error } = useQuery<Comment[], Error>(
    ['fetch-comments', externalIds],
    async () => {
      return list({
        parent: { externalIds, resourceType },
        project,
      }).then((comments) => {
        dispatch(
          commentsSlice.actions.merge({
            comments,
          })
        );
        return comments;
      });
    },
    {
      enabled:
        !!user && !!externalIds && externalIds.length > 0 && !!resourceType,
      staleTime: dayjs.duration(2, 'minutes').asMilliseconds(),
    }
  );

  return {
    invalidate,
    loading: isLoading,
    loadingError: error,
    getComments,
    getActiveComments,
  };
};

export const useComments = ({
  externalId,
  resourceType,
}: Parent): UseComments => {
  const dispatch = useDispatch();
  const { project } = useProjectContext();

  const createComment = (
    comment: NewComment,
    datasetExternalId: string,
    notificationDetails: NotificationDetails
  ): Promise<void> => {
    PerfMetrics.trackPerfStart(METRICS.CommentCreation);
    return create({
      parent: { externalId, resourceType },
      comment,
      project,
      datasetExternalId,
      notificationDetails,
    })
      .then((newComment) => {
        dispatch(
          commentsSlice.actions.create({
            parentExternalId: externalId,
            newComment,
          })
        );
        PerfMetrics.trackPerfEnd(METRICS.CommentCreation);
        PerfMetrics.logSuccessEvent(METRICS.CommentCreation);
      })
      .catch((e) => {
        PerfMetrics.logFailureEvent(METRICS.CommentCreation);
        throw e;
      });
  };

  const editComment = (
    comment: Comment,
    action: 'edited' | 'deleted',
    notificationDetails: NotificationDetails
  ): Promise<void> => {
    const metricName =
      action === 'edited' ? METRICS.CommentUpdate : METRICS.CommentDeletion;
    PerfMetrics.trackPerfStart(metricName);
    return edit({
      comment,
      parent: { externalId, resourceType },
      action,
      project,
      notificationDetails,
    })
      .then((editedComment) => {
        dispatch(
          commentsSlice.actions.edit({
            parentExternalId: externalId,
            editedComment,
          })
        );
        PerfMetrics.trackPerfEnd(metricName);
        PerfMetrics.logSuccessEvent(metricName);
      })
      .catch((e) => {
        PerfMetrics.logFailureEvent(metricName);
        throw e;
      });
  };

  return {
    createComment,
    editComment: (comment: Comment, notificationDetails: NotificationDetails) =>
      editComment(comment, 'edited', notificationDetails),
    deleteComment: (
      comment: Comment,
      notificationDetails: NotificationDetails
    ) => editComment(comment, 'deleted', notificationDetails),
  };
};
