import React, { useState, useMemo } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { useDateFormats } from 'utils/datetime';
import { useMetrics } from '@cognite/metrics';
import { Body, Button, Modal, toast } from '@cognite/cogs.js';

import { reportException } from '@cognite/react-errors';
import { exportData, exportDataClientSide } from 'utils/export';
import styled from 'styled-components/macro';

import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import isObject from 'lodash/isObject';
import { useUnitConversion } from 'features/unitConversion';
import { usePreferences } from 'features/preferences';
import useConfig from 'hooks/useConfig';
import { useProjectContext } from 'containers/AuthContainer';
import useNavigation from 'utils/useNavigation';
import { Pages } from 'utils/models/enums';
import { useWellListItems } from 'utils/models/wellListItems';
import { getHeaders, makeCsv } from 'utils/export/csv';
import { createWellSummaryExport } from 'utils/models/wellListItems/exportWellSummary';
import { useQuery } from 'react-query';
import { getEnrichmentConfig } from 'hooks/useGraphQlQuery';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { useCollections } from 'features/collections';
import { useTimeRange } from 'features/timeRange';
import createToastOptions from 'utils/toasts/createToastOptions';
import Contents from './Contents';
import WellSummaryContents from './Contents/WellSummaryContents';

export type DateRange = [number, number];

export type ProductionDataOptions = {
  [product: string]: {
    unit: string;
    displayName: string;
  };
};

export type ExportWellSummaryDataOptions = {
  [key: string]: { export: boolean; product?: string; type?: string };
};

export type ExportDataOptions = {
  deferments?: boolean;
  bestDayDeferments?: boolean;
  deviations?: boolean;
  comments?: boolean;
  bestDay?: boolean;
  productionData?: ProductionDataOptions;
};

export type FormatSpec = {
  dateRange: DateRange;
  data: ExportDataOptions;
};

export type FormatWellSummarySpec = {
  data: ExportWellSummaryDataOptions;
};

export type Props = {
  onClose: () => void;
};

const ExportModal = ({ onClose }: Props) => {
  const metrics = useMetrics('Export');
  const { t } = useTranslation('ExportModal');
  const { t: exportContent } = useTranslation('ExportModalContents');
  const { date } = useDateFormats();
  const { currentPage, isCollectionPage } = useNavigation();
  const { activeCollection } = useCollections();
  const { loading: wellsLoading, wellListItems } = useWellListItems();
  const { start, end } = useTimeRange();

  const { datasets } = useSelector((state: RootState) => state.datasets);

  const { project } = useProjectContext();
  const { selectedAsset } = useCurrentAsset();
  const [exporting, setExporting] = useState(false);
  const [visible, setVisible] = useState(true);
  const { productConversions } = useUnitConversion();
  const { rootAssetConfig } = useConfig();
  const { units } = usePreferences();
  const [exportSpec, setExportSpec] = useState<FormatSpec>();
  const [exportWellSummarySpec, setExportWellSummarySpec] =
    useState<FormatWellSummarySpec>();

  const shouldEnableExportButton = useMemo(() => {
    return (
      (exportSpec?.data &&
        Object.values(exportSpec?.data).some((value) =>
          isObject(value) ? Object.values(value).length > 0 : !!value
        )) ||
      (exportWellSummarySpec?.data &&
        Object.values(exportWellSummarySpec?.data).some((value) =>
          isObject(value) ? Object.values(value).length > 0 : !!value
        ))
    );
  }, [exportSpec, exportWellSummarySpec]);

  const renderTitle = (dateRange: DateRange) => {
    const [start, end] = dateRange;
    const title = t('export-file_title', {
      defaultValue: 'BestDay export',
    });
    return `${title} ${date(start)} - ${date(end)}.zip`;
  };

  const { data: config } = useQuery({
    queryKey: ['getEnrichmentConfig', datasets?.CUSTOMER_CONFIGURATION],
    queryFn: () =>
      getEnrichmentConfig({
        templateInfo: rootAssetConfig?.templates!,
        dataSetId: datasets?.CUSTOMER_CONFIGURATION,
      }),
    enabled: !!rootAssetConfig?.templates && !!datasets?.CUSTOMER_CONFIGURATION,
  });

  const beginExport = () => {
    if (!selectedAsset?.externalId || !exportSpec || !rootAssetConfig) {
      return;
    }

    metrics.track('ExportOptions_click');

    if (exportSpec.data.comments) {
      metrics.track('Comments_exported');
    }
    if (exportSpec.data.deferments) {
      metrics.track('Deferments_exported');
    }
    if (exportSpec.data.bestDayDeferments) {
      metrics.track('Bestday_Deferments_exported');
    }
    if (exportSpec.data.deviations) {
      metrics.track('Deviations_exported');
    }

    const selectedProducts = Object.values(
      exportSpec.data.productionData || {}
    );

    if (
      exportSpec.data.productionData &&
      selectedProducts.some((value) => !!value)
    ) {
      metrics.track('Production.Production_exported');

      if (selectedProducts.every((value) => !!value)) {
        metrics.track('Production.AllProducts_exported');
      }
    }

    setExporting(true);
    exportData({
      asset: selectedAsset,
      spec: exportSpec,
      fileTitle: renderTitle(exportSpec.dateRange),
      productConversions,
      units,
      templateInfo: rootAssetConfig.templates,
      project,
    })
      .then(() => {
        setVisible(false);
        onClose();
      })
      .catch((e) => {
        reportException(e);
        setExporting(false);
      });
  };

  const beginExportWellSummary = async () => {
    if (!exportWellSummarySpec || !rootAssetConfig) {
      return;
    }

    setExporting(true);

    metrics.track('ExportWellSummaryOptions_click');

    Object.entries(exportWellSummarySpec.data).forEach(([key, value]) => {
      if (value.export) {
        metrics.track(`${key}_exported`);
      }
    });

    const wellListItemsForExport = createWellSummaryExport(
      wellListItems,
      exportWellSummarySpec,
      config?.capacities?.find((it) => it.isReference)?.type
    );

    const potentialHeaders = Object.entries(exportWellSummarySpec.data)
      .filter(([_key, value]) => value.export)
      .map(([key, _value]) => key);
    const headers = getHeaders(potentialHeaders, wellListItemsForExport);
    const csv = makeCsv(headers, wellListItemsForExport, (header: string) =>
      exportContent(`${header}_label`, { defaultValue: header })
    );

    const createFileName = () => {
      const fileName = [date(start), date(end), 'wellsummary_export'];
      if (isCollectionPage) {
        fileName.push('collection');
        if (activeCollection?.name) {
          fileName.push(activeCollection.name);
        }
      } else {
        fileName.push('system');
        if (selectedAsset?.name) {
          fileName.push(selectedAsset.name);
        }
      }
      return `${fileName.join('_')}.csv`;
    };

    exportDataClientSide(csv, createFileName(), 'text/csv;encoding:utf-8')
      .then(() => {
        setVisible(false);
        onClose();
      })
      .catch((error) => {
        reportException(error);
        setExporting(false);
        toast.error(
          <Body level={2}>
            <Trans t={t} i18nKey="toast-export-well-summary-error">
              Could not export well summary{' '}
              {{ errorMessage: error?.message ?? '' }}
            </Trans>
          </Body>,
          createToastOptions()
        );
      });
  };

  const handleCancelClick = () => {
    setVisible(false);
    onClose();
  };

  const title = useMemo(() => {
    if (currentPage === Pages.Wells) {
      return t('wellsummary_title', { defaultValue: 'Well Summary Export' });
    }
    return t('title', { defaultValue: 'Export data' });
  }, [currentPage, t]);

  const uniqeProducts = useMemo(() => {
    const products = new Set(wellListItems.map((it) => it.type));
    return Array.from(products)
      .filter(Boolean)
      .map((product) => {
        return {
          type: product,
          capacities: Array.from(
            new Set(
              wellListItems
                .filter((it) => it.type === product)
                .map((it) => it.capacities.map((it) => it.type))
                .flat()
            )
          ).map((it) => {
            const reference = config?.capacities.find((it) => it.isReference);
            return {
              type: it,
              isReference: !!reference?.isReference && reference.type === it,
            };
          }),
        };
      });
  }, [config?.capacities, wellListItems]);

  return (
    <StyledExportModal
      title={title}
      appElement={document.getElementById('root')!}
      onCancel={handleCancelClick}
      visible={visible}
      footer={[
        <Button key="cancel" onClick={handleCancelClick} type="secondary">
          <Trans t={t} i18nKey="cancel_button">
            Cancel
          </Trans>
        </Button>,
        <Button
          key="export"
          onClick={
            currentPage === Pages.Wells ? beginExportWellSummary : beginExport
          }
          disabled={wellsLoading || !shouldEnableExportButton || exporting}
          loading={exporting}
          type="primary"
        >
          <Trans t={t} i18nKey="export_button">
            Export
          </Trans>
        </Button>,
      ]}
    >
      {currentPage === Pages.Wells ? (
        <WellSummaryContents
          products={uniqeProducts}
          onChange={setExportWellSummarySpec}
        />
      ) : (
        <Contents onChange={setExportSpec} />
      )}
    </StyledExportModal>
  );
};

const StyledExportModal = styled(Modal)`
  .cogs-modal-header {
    font-size: var(--cogs-t5-font-size);
    font-weight: 600;
  }

  .cogs-modal-footer {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-left: 8px;
    }
  }
`;

export default withI18nSuspense(ExportModal);
