import LRUCache from 'lru-cache';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getClient } from 'utils/cognitesdk';
import {
  Asset,
  ExternalId,
  IdEither,
  InternalId,
  Timeseries,
  Label,
} from '@cognite/sdk';

dayjs.extend(duration);

const assetCache = new LRUCache<string | number, Asset>({
  maxAge: dayjs.duration(1, 'hour').asMilliseconds(),
  max: 10 * 1000,
});

const timeSeriesCache = new LRUCache<string, Promise<Timeseries>>({
  maxAge: dayjs.duration(1, 'hour').asMilliseconds(),
  max: 10 * 1000,
});

export const clear = () => {
  assetCache.reset();
  timeSeriesCache.reset();
};

const isExternalId = (idEither: IdEither): idEither is ExternalId => {
  return !(idEither as InternalId).id;
};

export const getAsset = async (idEither: IdEither) => {
  const key = isExternalId(idEither) ? idEither.externalId : idEither.id;

  const cached = assetCache.get(key);
  if (cached) {
    return cached;
  }

  const promise = getClient()
    .assets.retrieve([idEither])
    .then((results) => {
      if (results.length === 0) {
        throw new Error('No matching asset found');
      }
      const [result] = results;
      if (result) {
        assetCache.set(key, result);
      }
      return result;
    })
    .catch((e) => {
      assetCache.del(key);
      throw e;
    });
  return promise;
};

export type GetAssetsOptions = {
  labels: {
    containsAll?: Label[];
    containsAny?: Label[];
  };
};

export const getAssets = async (
  eitherIds: IdEither[],
  options: GetAssetsOptions = { labels: {} }
): Promise<Asset[]> => {
  const {
    labels: { containsAll = [], containsAny = [] },
  } = options;
  const { cached, missing } = eitherIds.reduce(
    (acc, eitherId) => {
      const cacheEntry = assetCache.get(
        isExternalId(eitherId) ? eitherId.externalId : eitherId.id
      );
      if (cacheEntry) {
        return {
          ...acc,
          cached: [...acc.cached, cacheEntry],
        };
      }
      return {
        ...acc,
        missing: [...acc.missing, eitherId],
      };
    },
    {
      cached: [] as Asset[],
      missing: [] as IdEither[],
    }
  );

  const applyLabelsFilter = (assets: Asset[]): Asset[] => {
    const assetsWithLabels = assets.filter(
      (asset) => asset.labels && asset.labels.length > 0
    );

    if (containsAll.length === 0 && containsAny.length === 0) {
      return assetsWithLabels;
    }

    return assetsWithLabels.filter((asset) => {
      const assetLabelsExtIds = asset.labels!.map((label) => label.externalId);

      return (
        (containsAll.length === 0 ||
          containsAll.every((label) =>
            assetLabelsExtIds.includes(label.externalId)
          )) &&
        (containsAny.length === 0 ||
          containsAny.some((label) =>
            assetLabelsExtIds.includes(label.externalId)
          ))
      );
    });
  };

  const filteredCached = applyLabelsFilter(cached);

  if (missing.length > 0) {
    const missingAssets = await getClient()
      .assets.retrieve(missing, {
        ignoreUnknownIds: true,
      })
      .then(applyLabelsFilter);

    const isId = !isExternalId(eitherIds[0]);
    if (isId) {
      missingAssets.forEach((missingAsset) =>
        assetCache.set(missingAsset.id, missingAsset)
      );
    } else {
      missingAssets.forEach((missingAsset) =>
        assetCache.set(missingAsset.externalId!, missingAsset)
      );
    }

    return missingAssets.concat(filteredCached);
  }
  return filteredCached;
};

export const getTimeSeries = async (
  externalIds: string[]
): Promise<(Timeseries | undefined)[]> => {
  const results = await getClient().timeseries.retrieve(
    externalIds.map((extId) => ({
      externalId: extId,
    })),
    {
      ignoreUnknownIds: true,
    }
  );

  const resultsMap = results.reduce((acc, cur) => {
    if (!cur.externalId) {
      return acc;
    }
    return {
      ...acc,
      [cur.externalId]: cur,
    };
  }, {} as { [id: string]: Timeseries });

  return externalIds.map((extId) => {
    return resultsMap[extId];
  });
};
