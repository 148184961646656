import { CogniteEvent } from '@cognite/sdk';
import dayjs from 'dayjs';
import { Field } from 'features/defermentMatrix/types';

import values from 'lodash/values';
import { DeviationGroup, DeviationSegment, DeviationSegmentVolume } from '.';
import { Deferment, DefermentVolume } from '../deferments';
import { Deviation, DeviationVolume } from '../deviations';

export const getTotalSegmentVolume = (volumes: DeviationSegmentVolume[]) => {
  return volumes.find((volume) => volume.type === 'hydrocarbon')?.volume ?? 0;
};

export const getIfIsGroup = (
  toBeDetermined: Deviation | DeviationGroup
): toBeDetermined is DeviationGroup => {
  if ((toBeDetermined as DeviationGroup).deviationSegments) {
    return true;
  }
  return false;
};

export const adjustToEndOfLastDay = (date: number | Date) => {
  return dayjs(date)
    .subtract(dayjs.duration(1, 'd'))
    .hour(23)
    .minute(50)
    .second(59)
    .toDate();
};

export const convertToDeviationGroups = (
  events: CogniteEvent[],
  perCustomerFields: Field[]
): DeviationGroup[] => {
  return events
    .map((event) => {
      const {
        externalId,
        startTime,
        endTime,
        assetIds,
        lastUpdatedTime,
        metadata,
        // @ts-ignore
        deviationSegments = [],
      } = event;

      if (
        !metadata ||
        !startTime ||
        !externalId ||
        !assetIds ||
        !assetIds.length
      ) {
        return null;
      }
      const startDate = new Date(startTime);
      const volumes = values(
        (deviationSegments as DeviationSegment[]).reduce((acc, { volumes }) => {
          volumes.forEach(({ type, volume }) => {
            // hardcoded for now. I think we should return unit in segment's volume from api
            const unit = type === 'h2o' ? 'M3' : 'BOE';
            const product = type.toUpperCase();
            if (acc[product]) {
              acc[product] = {
                ...acc[product],
                currentValue: acc[product].currentValue + volume,
              };
            } else {
              acc[product] = {
                product,
                currentValue: volume,
                unit,
                ratio: 1,
              };
            }
          });

          return acc;
        }, {} as { [product: string]: DeviationVolume })
      );

      const totalVolume =
        volumes.find((volume) => volume.product === 'HYDROCARBON')
          ?.currentValue ?? 0;
      const {
        status,
        autoUpdate,
        defermentName,
        category,
        facility,
        comment,
        ignoredComment,
        isBestDayDeferment,
        fractionOfBestDay,
        assetExternalId,
      } = metadata;

      const getStatus = () => {
        return status.toLowerCase();
      };

      const perCustomerProps = perCustomerFields.reduce((acc, field) => {
        acc[field.type] = metadata[field.type];
        return acc;
      }, {} as { [key: string]: string });

      return {
        externalId,
        startDate,
        // Set undefined endDate for ongoing group deviation
        // Adjust to end of previous day to show correct end date (events end at 00:00 the day after the real en date)
        endDate:
          autoUpdate !== 'true' && endTime
            ? adjustToEndOfLastDay(+endTime)
            : undefined,
        product: 'HYDROCARBON',
        lastUpdatedTime,
        volume: totalVolume,
        unit: 'BOE',
        volumes: volumes.filter((volume) => volume.product !== 'HYDROCARBON'),
        status: getStatus(),
        detectedDate: startDate,
        assetIds,
        deviationSegments,
        defermentName,
        category,
        facility,
        comment,
        ignoredComment,
        autoUpdate: autoUpdate === 'true',
        fractionOfBestDay: fractionOfBestDay ? +fractionOfBestDay : undefined,
        isBestDayDeferment: !!isBestDayDeferment,
        assetExternalId,
        ...perCustomerProps,
      };
    })
    .filter(Boolean) as Deviation[];
};

export const convertToBestDayDeferment = (events: CogniteEvent[]) => {
  return events
    .map((event) => {
      const {
        externalId,
        startTime,
        endTime,
        assetIds,
        lastUpdatedTime,
        metadata,
        // @ts-ignore
        deviationSegments = [],
      } = event;

      if (
        !metadata ||
        !startTime ||
        !externalId ||
        !assetIds ||
        !assetIds.length
      ) {
        return null;
      }

      const {
        defermentName,
        comment,
        category,
        choke,
        subChoke,
        reason,
        isBestDayDeferment,
        fractionOfBestDay,
        autoUpdate,
      } = metadata;

      if (!isBestDayDeferment) {
        return null;
      }

      const startDate = new Date(startTime);

      const volumes = values(
        (deviationSegments as DeviationSegment[]).reduce((acc, { volumes }) => {
          volumes
            .filter(({ type }) => type !== 'hydrocarbon')
            .forEach(({ type, volume }) => {
              // hardcoded for now. I think we should return unit in segment's volume from api
              const unit = type === 'h2o' ? 'M3' : 'BOE';
              const product = type.toUpperCase();

              if (acc[product]) {
                acc[product] = {
                  ...acc[product],
                  value: acc[product].value + volume,
                };
              } else {
                acc[product] = {
                  product,
                  value: volume,
                  unit,
                };
              }
            });

          return acc;
        }, {} as { [product: string]: DefermentVolume })
      );

      const volumeInCommonUnit = volumes.reduce((acc, { value, unit }) => {
        return unit === 'BOE' ? acc + value : acc;
      }, 0);

      return {
        externalId,
        name: defermentName,
        status: category || 'unplanned',
        type: 'actual',
        startTime: startDate,
        endTime:
          autoUpdate !== 'true' && endTime
            ? adjustToEndOfLastDay(+endTime)
            : undefined,
        volumes,
        reason,
        comment,
        choke,
        subChoke,
        volumeInCommonUnit,
        assetIds,
        lastUpdatedTime,
        fractionOfBestDay: +fractionOfBestDay,
        isBestDayDeferment: !!isBestDayDeferment,
      };
    })
    .filter(Boolean) as Deferment[];
};
