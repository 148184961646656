export type Sidecar = {
  __sidecarFormatVersion: number;
  applicationId: string;
  applicationName: string;
  cdfCluster: string;
  appsApiBaseUrl: string;
  cdfApiBaseUrl: string;
  docsSiteBaseUrl: string;
  nomaApiBaseUrl: string;
  bestdayApiBaseUrl: string;
  cogniteChartsBaseUrl: string;
  userManagementServiceBaseUrl: string;
  privacyPolicyUrl: string;
  unleash: string;
  frontendMetricsBaseUrl: string;
};

// These variables are only used locally.
// Sidecar will override everything on staging / prod envs.
const generateBaseUrls = (prod = false) => {
  if (prod) {
    return {
      __sidecarFormatVersion: 1,
      airApiBaseUrl: 'https://air-api.cognite.ai',
      aadApplicationId: '2efca67b-3da5-4c11-8316-609c470f9873',
      cdfCluster: '',
      applicationId: 'bestday',
      applicationName: 'Best Day demo',
      appsApiBaseUrl: 'https://apps-api.cognite.ai',
      bestdayApiBaseUrl: 'https://bestday-api.cognite.ai',
      calculationServiceBaseUrl:
        'https://calculation-service.development.cognite.ai',
      cogniteChartsBaseUrl: 'https://charts.cogniteapp.com',
      commentServiceBaseUrl: 'https://comment-service.cognite.ai',
      contextServiceBaseUrl: 'https://context-service.development.cognite.ai',
      dataQualityMonitoringApiBaseUrl:
        'https://data-quality-monitoring-api.development.cognite.ai',
      digitalCockpitApiBaseUrl: 'https://digital-cockpit-api.cognite.ai',
      discoverApiBaseUrl: 'https://discover-api.cognite.ai',
      docsSiteBaseUrl: 'https://docs.cognite.com',
      frontendMetricsBaseUrl: 'https://frontend-metrics.cognite.ai',
      infieldApiBaseUrl: 'https://infield-api.cognite.ai',
      infieldCacheApiBaseUrl: 'https://infield-cache-api.cognite.ai',
      mpServiceBaseURL: 'https://maintenance-planner-service.cognite.ai',
      openaiProxyBaseUrl: 'https://api.cogniteapp.com/openai-proxy',
      powerOpsApiBaseUrl: 'https://power-ops-api.cognite.ai',
      privacyPolicyUrl: 'https://www.cognite.com/en/policy',
      remoteApiBaseUrl: 'https://api.cogniteapp.com/remote-api',
      seismicApiBaseUrl: 'https://seismic-api.development.cognite.ai',
      simconfigApiBaseUrl: 'https://simconfig-api.cognite.ai',
      snifferServiceBaseUrl: 'https://sniffer-service.cognite.ai',
      unleash: 'v2Qyg7YqvhyAMCRMbDmy1qA6SuG8YCBE',
      userManagementServiceBaseUrl:
        'https://user-management-service.cognite.ai',
    };
  }
  return {
    __sidecarFormatVersion: 1,
    airApiBaseUrl: 'https://air-api.staging.cognite.ai',
    cdfCluster: '',
    aadApplicationId: 'ee6349b9-c345-471f-abb6-571d917c1bef',
    applicationId: 'bestday-staging',
    applicationName: 'Best Day demo (staging)',
    appsApiBaseUrl: 'https://apps-api.staging.cognite.ai',
    bestdayApiBaseUrl: 'https://bestday-api.staging.cognite.ai',
    calculationServiceBaseUrl:
      'https://calculation-service.development.cognite.ai',
    cogniteChartsBaseUrl: 'https://charts.staging.cogniteapp.com',
    commentServiceBaseUrl: 'https://comment-service.staging.cognite.ai',
    contextServiceBaseUrl: 'https://context-service.development.cognite.ai',
    dataQualityMonitoringApiBaseUrl:
      'https://data-quality-monitoring-api.development.cognite.ai',
    digitalCockpitApiBaseUrl: 'https://digital-cockpit-api.staging.cognite.ai',
    discoverApiBaseUrl: 'https://discover-api.staging.cognite.ai',
    docsSiteBaseUrl: 'https://docs.cognite.com',
    frontendMetricsBaseUrl: 'https://frontend-metrics.staging.cognite.ai',
    infieldApiBaseUrl: 'https://infield-api.staging.cognite.ai',
    infieldCacheApiBaseUrl: 'https://infield-cache-api.staging.cognite.ai',
    mpServiceBaseURL: 'https://maintenance-planner-service.staging.cognite.ai',
    openaiProxyBaseUrl: 'https://api.staging.cogniteapp.com/openai-proxy',
    powerOpsApiBaseUrl: 'https://power-ops-api.staging.cognite.ai',
    privacyPolicyUrl: 'https://www.cognite.com/en/policy',
    remoteApiBaseUrl: 'https://api.staging.cogniteapp.com/remote-api',
    seismicApiBaseUrl: 'https://seismic-api.development.cognite.ai',
    simconfigApiBaseUrl: 'https://simconfig-api.staging.cognite.ai',
    snifferServiceBaseUrl: 'https://sniffer-service.staging.cognite.ai',
    unleash: 'v2Qyg7YqvhyAMCRMbDmy1qA6SuG8YCBE',
    userManagementServiceBaseUrl:
      'https://user-management-service.staging.cognite.ai',
  };
};

const PROD = process.env.REACT_APP_ENV === 'production';

export const SIDECAR: Sidecar = {
  ...generateBaseUrls(PROD),
  __sidecarFormatVersion: 0,
  enableUserManagement: true,
  cdfApiBaseUrl: `https://api.cognitedata.com`,
  // We should always have a token
  unleash: process.env.REACT_APP_UNLEASH_API_TOKEN || '',
  /* eslint-disable @typescript-eslint/no-explicit-any */
  // eslint-disable-next-line no-underscore-dangle
  ...(window as any).__cogniteSidecar,
} as const;

// eslint-disable-next-line no-underscore-dangle
(window as any).__cogniteSidecar = SIDECAR;
